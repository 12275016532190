export const toEnglishNumber = (perNum) => {
  var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  // var en = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  let arr = perNum.split("");
  let newWord = "";
  for (var i = 0; i < arr.length; i++) {
    if (pn.indexOf(arr[i]) !== -1) {
      newWord += pn.indexOf(arr[i]);
    } else {
      newWord += arr[i];
    }
  }
  return newWord;
};