import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardHome from "./pages/DashboardHome/DashboardHome";
import Login from "./pages/Login/Login";
import NewDoc from "./pages/NewDoc/NewDoc";
import Setting from "./pages/Setting/Setting";
import DocList from "./pages/DocList/DocList";
import ProtectedRoute from "./routes/ProtectedRoute";
function App() {
  useEffect(() => {
    if (!localStorage.getItem("hyperlinkForAllDocs")) {
      localStorage.setItem("hyperlinkForAllDocs", true)
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="Dashboard" element={<DashboardHome />} />
          <Route path="documentsList" element={<DocList />} />
          <Route path="createNewDoc" element={<NewDoc />} />
          <Route path="editDocument" element={<NewDoc />}>
            <Route path=":id" element={<NewDoc />} />
          </Route>
          <Route path="editForHyperLink" element={<NewDoc />}>
            <Route path=":id" element={<NewDoc />} />
          </Route>
          <Route path="setting" element={<Setting />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
