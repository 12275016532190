import React, { useEffect, useState } from "react";
import { SITEURL } from "../../utils/Constants";

import defaultProfile from "../../assets/images/defProfile.jpg";
import { DateInput } from "react-hichestan-datetimepicker";
import "./Dashboard.scss";
import { Modal, Button, Image } from "react-bootstrap";
import {
  Speedometer2,
  FileEarmarkPlus,
  CardText,
  Gear,
  Globe2,
  InfoCircle,
  BoxArrowDownLeft,
  Telegram,
  Instagram,
  Envelope,
  Whatsapp,
  Telephone,
  LayerBackward,
} from "react-bootstrap-icons";
import { useNavigate, Link } from "react-router-dom";
import Portal from "../../components/Portal/Portal";
import Loading from "../../components/Loading/Loading";
import Joyride from "react-joyride";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import { IMAGEURLS } from "../../utils/Constants";
import swal from "sweetalert";
import { setCounters, setImageProfile } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
function Dashboard(props) {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [tourState, setTourState] = useState(false);
  const [nowDate, setNowDate] = useState("");
  const [nowTime, setNowTime] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [backUpModalShow, setBackUpModalShow] = useState(false);
  const [backUps, setBackUps] = useState({
    backUpStartDate: "",
    backUpEndDate: "",
    backUpStartDateErr: "",
    backUpEndDateErr: "",
    backUpLink: "",
  });
  const [tourSteps, setTourSteps] = useState([
    {
      target: "#documentsList",
      content:
        "از این بخش میتوانید لیست اسناد خود را مشاهده کرده و آنها را ویرایش و حذف نمایید.",
    },
    {
      target: "#createNewDoc",
      content: "برای ساختن سند جدید از این بخش وارد شوید.",
    },
    {
      target: "#setting",
      content: "تنظیمات شامل بخش عمومی و کاربران ( نیازمند دسترسی ادمین ).",
    },
    {
      target: "#backUp",
      content: "گرفتن فایل پشتیبانی از اطلاعات به صورت بازه زمانی.",
    },
    {
      target: "#aboutUs",
      content:
        "برای شناخت بیشتر و آشنایی با شرکت ما و همچنین، شبکه های اجتماعی و ارتباط با ما از این گزینه استفاده نمایید.",
    },
  ]);
  const checkTime = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };
  const logout = () => {
    localStorage.removeItem("_data");
    navigate("/");
  };
  const getBackUp = () => {
    if (backUps.backUpStartDate === "" && backUps.backUpEndDate === "") {
      setBackUps({
        ...backUps,
        backUpStartDateErr: "تعیین فیلد تاریخ شروع پشتیبان الزامی است",
        backUpEndDateErr: "تعیین فیلد تاریخ پایان پشتیبان الزامی است",
      });
    } else if (backUps.backUpStartDate === "") {
      setBackUps({
        ...backUps,
        backUpStartDateErr: "تعیین فیلد تاریخ شروع پشتیبان الزامی است",
        backUpEndDateErr: "",
      });
    } else if (backUps.backUpEndDate === "") {
      setBackUps({
        ...backUps,
        backUpStartDateErr: "",
        backUpEndDateErr: "تعیین فیلد تاریخ پایان پشتیبان الزامی است",
      });
    } else {
      AxiosInterceptor.post("/BackUp", {
        from: backUps.backUpStartDate,
        to: backUps.backUpEndDate,
      }).then((response) => {
        if (!response.data.includes("error")) {
          setBackUps({
            ...backUps,
            backUpStartDateErr: "",
            backUpEndDateErr: "",
            backUpLink: SITEURL + response.data,
          });
          document.querySelector("#backUpLink").click();
          document.querySelector("#closeBackUpModal").click();
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data,
            button: "متوجه شدم",
          });
        }
      });
    }
  };
  useEffect(() => {
    let nowDate = document.querySelector(".date-input-with-dialog-input").value;
    setNowDate(nowDate);
    var clockInterVal = setInterval(() => {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = checkTime(m);
      s = checkTime(s);
      setNowTime(h + ":" + m + ":" + s);
    }, 1000);
    return () => {
      clearInterval(clockInterVal);
    };
  }, []);
  useEffect(() => {
    let myData = JSON.parse(localStorage.getItem("_data"));
    dispatch(
      setCounters({
        bargCount: myData.bargCount,
        userCount: myData.userCount,
        docCount: myData.docCount,
      })
    );
    setUserData(myData);
    if (!myData.user.isAdmin) {
      setTourSteps([
        {
          target: "#documentsList",
          content:
            "از این بخش میتوانید لیست اسناد خود را مشاهده کرده و آنها را ویرایش و حذف نمایید.",
        },
        {
          target: "#createNewDoc",
          content: "برای ساختن سند جدید از این بخش وارد شوید.",
        },
        {
          target: "#aboutUs",
          content:
            "برای شناخت بیشتر و آشنایی با شرکت ما و همچنین، شبکه های اجتماعی و ارتباط با ما از این گزینه استفاده نمایید.",
        },
      ]);
    }
  }, []);
  useEffect(() => {
    AxiosInterceptor.post("/Get", { type: "All" }).then((response) => {
      if (!response.data.includes("error")) {
        // console.log({response})
        response.data?.forEach((item) => {
          if (item.key === "Profile") {
            if (item.value[0].value) {
              dispatch(setImageProfile(IMAGEURLS + item.value[0].value));
            }
          }
        });
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
    });
    let url = window.location.href;
    let pageUrl = url.substring(url.lastIndexOf("/") + 1).replace("#!", "");
    setTimeout(() => {
      let item = document.getElementById(pageUrl);
      if (item) {
        item.classList.add("nav-link-active");
      }
    }, 200);
  }, []);
  return (
    <div className="nav-fixed">
      <Joyride
        steps={tourSteps}
        continuous={true}
        disableOverlayClose={true}
        hideCloseButton={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        run={tourState}
        locale={{
          back: "قبلی",
          close: "بستن",
          last: "پایان",
          next: "بعدی",
          open: "باز کردن",
          skip: "رد شدن",
        }}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#fff",
            overlayColor: "rgba(0,0,0,0.75)",
            primaryColor: "#5753c9",
            textColor: "#000",
            width: 900,
            zIndex: 2000,
          },
        }}
      />
      <nav
        className="topnav navbar navbar-expand justify-content-between justify-content-sm-start navbar-light bg-white"
        id="sidenavAccordion"
      >
        <span className="navbar-brand">داشبورد مدیریت اسناد دیجیتال</span>
        <ul className="navbar-nav align-items-center me-auto">
          <li>
            امروز {nowDate} - {nowTime}
          </li>
          <li className="nav-item no-caret me-3 me-lg-4">
            <button className="mx-3 btn logOutBtn" onClick={logout}>
              خروج <BoxArrowDownLeft />
            </button>
          </li>
          <div className="d-none">
            <DateInput name={"nowDate"} value={new Date()} disabled={true} />
          </div>
        </ul>
      </nav>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sidenav shadow-right sidenav-light">
            <div className="sidenav-menu">
              <div className="nav accordion" id="accordionSidenav">
                <div className="user-profile set-column-middle">
                  <Image
                    src={
                      reduxState.profile ? reduxState.profile : defaultProfile
                    }
                    roundedCircle
                    className="mb-2"
                  />
                  <ul className="unstyled-list w-100 p-1 m-0">
                    <li className="text-center mb-2">
                      <strong>{userData.companyName}</strong>
                    </li>
                    <li>
                      نام کاربری : <strong>{userData.user?.username}</strong> /
                      نوع کاربر :{" "}
                      <strong>
                        {userData.user?.isAdmin ? "ادمین" : "کاربرمعمولی"}
                      </strong>
                    </li>
                    <li>
                      تعداد پرسنل : <strong>{reduxState.userCount}</strong> /
                      تعداد برگ : <strong>{reduxState.bargCount}</strong>
                    </li>
                    <li>
                      تعداد اسناد دیجیتال :{" "}
                      <strong>{reduxState.docCount}</strong>
                    </li>
                    <li>
                      شماره سریال : <strong>{userData.serialNumber}</strong>
                    </li>
                  </ul>
                </div>
                <div className="sidenav-menu-heading">خانه</div>
                <Link id="Dashboard" className="nav-link " to="/Dashboard">
                  <div className="nav-link-icon">
                    <Speedometer2 style={{ color: "#5753C9" }} />
                  </div>
                  داشبورد
                </Link>
                <div className="sidenav-menu-heading">اسناد</div>
                <Link
                  id="documentsList"
                  className="nav-link"
                  to="/documentsList"
                >
                  <div className="nav-link-icon">
                    <CardText style={{ color: "#5753C9" }} />
                  </div>
                  مرور اسناد
                </Link>
                {props.imSaveNewDoc ? (
                  <a
                    id="createNewDoc"
                    className="nav-link"
                    href="#!"
                    onClick={props.checkForSavePrevDoc}
                  >
                    <div className="nav-link-icon">
                      <FileEarmarkPlus style={{ color: "#5753C9" }} />
                    </div>
                    سند جدید
                  </a>
                ) : (
                  <Link
                    id="createNewDoc"
                    className="nav-link"
                    to="/createNewDoc"
                  >
                    <div className="nav-link-icon">
                      <FileEarmarkPlus style={{ color: "#5753C9" }} />
                    </div>
                    سند جدید
                  </Link>
                )}
                {userData.user?.isAdmin && (
                  <>
                    <div className="sidenav-menu-heading">دسترسی های ادمین</div>
                    <Link id="setting" className="nav-link" to="/setting">
                      <div className="nav-link-icon">
                        <Gear style={{ color: "#5753C9" }} />
                      </div>
                      تنظیمات
                    </Link>
                    <a
                      id="backUp"
                      className="nav-link"
                      href="#!"
                      onClick={() => setBackUpModalShow(true)}
                    >
                      <div className="nav-link-icon">
                        <LayerBackward style={{ color: "#5753C9" }} />
                      </div>
                      پشتیبان گیری
                    </a>
                  </>
                )}
                <div className="sidenav-menu-heading">سایر</div>
                <a
                  id="aboutUs"
                  className="nav-link"
                  href="#!"
                  onClick={() => setModalShow(true)}
                >
                  <div className="nav-link-icon">
                    <Globe2 style={{ color: "#5753C9" }} />
                  </div>
                  درباره ما
                </a>
                <a
                  id="help"
                  className="nav-link"
                  href="#!"
                  onClick={() => {
                    setTimeout(() => {
                      document.querySelector(".react-joyride__beacon")
                        ? document
                            .querySelector(".react-joyride__beacon")
                            .click()
                        : console.log("");
                    }, 500);
                    setTourState(true);
                  }}
                >
                  <div className="nav-link-icon">
                    <InfoCircle style={{ color: "#5753C9" }} />
                  </div>
                  راهنما
                </a>
              </div>
            </div>
          </nav>
        </div>
        <div id="layoutSidenav_content">
          <Loading />
          <main>{props.children}</main>
          <footer className="footer-admin mt-auto set-row-middle">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-12 text-center small">
                  &copy; تمامی حقوق این وبسایت برای شرکت نماپرداز امیر محفوظ
                  است.
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <Portal show={backUpModalShow}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            پشتیبان گیری
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3 text-center">
            <strong>تاریخ شروع و پایان پشتیبان گیری خود را مشخص نمایید</strong>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-6 mb-3 set-column-middle align-items-start">
              <label htmlFor="">از تاریخ</label>
              <DateInput
                name="backUpStartDate"
                onChange={(e) => {
                  setBackUps({
                    ...backUps,
                    backUpStartDate: e.target.value.split("T")[0],
                  });
                }}
                autoOk={true}
                value={backUps.backUpStartDate}
              />
              <p className="error">{backUps.backUpStartDateErr}</p>
            </div>
            <div className="col-sm-12 col-lg-6 mb-3 set-column-middle align-items-start">
              <label htmlFor="">تا تاریخ</label>
              <DateInput
                name="backUpEndDate"
                onChange={(e) => {
                  setBackUps({
                    ...backUps,
                    backUpEndDate: e.target.value.split("T")[0],
                  });
                }}
                autoOk={true}
                value={backUps.backUpEndDate}
              />
              <p className="error">{backUps.backUpEndDateErr}</p>
            </div>
          </div>
          <div className="row">
            <a
              id="backUpLink"
              className="d-none"
              href={backUps.backUpLink}
              download
            >
              backUpLink
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={getBackUp} variant={"success"}>
            پشتیبان گیری
          </Button>
          <Button
            onClick={() => {
              setBackUps({
                backUpStartDate: "",
                backUpEndDate: "",
                backUpStartDateErr: "",
                backUpEndDateErr: "",
                backUpLink: "",
              });
              setBackUpModalShow(false);
            }}
            variant={"danger"}
          >
            انصراف
          </Button>
        </Modal.Footer>
      </Portal>
      <Portal show={modalShow}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            درباره ما
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            امروزه اهمیت و ضرورت کاهش تصدی گری برای سازمان ها و شرکت های دولتی و
            خصوصی کاملاً احساس شده و مدیران کلان و میانی ترجیح می دهند تا با
            برون سپاری بخشی از وظائف و ماموریت خود به سایر مجموعه ها ، ضمن سرعت
            بخشی در تحقق اهداف ، از اتلاف وقت در واگذاری و انجام امور پرهیز
            نمایند .
          </p>
          <p className="text-center">
            موسسه نماپرداز امیر در پاییز 1399 تاسیس و توانست با بهره گیری از
            تجربه 30 ساله کادر اجرائی خود طی این مدت ، اعتماد و رضایت کارفرمایان
            را جلب نماید . این موسسه در اجرای موضوعات فعالیت خود به شرح زیر
            آمادگی همکاری دارد : انجام کلیه امور برون سپاری شده شرکت ها و سازمان
            های دولتی و خصوصی در حوزه توافقات فی مابین . مستند سازی ، پالایش
            اسناد و مدارک ، گسترش سیستم های بایگانی و به روز رسانی آنها . برنامه
            ریزی ، طراحی و تولید محتوا در قالب بانک های اطلاعاتی به منظور پالایش
            و مستند سازی اسناد سازمان ها و شرکت های دولتی و خصوصی . پیگیری اجرای
            دستورالعمل های اداری و فنی و مهندسی . برگزاری نشست ها ، همایش ها ،
            نمایشگاه ها ، کارگاه های اطلاع رسانی کوتاه و بلند مدت . انجام پروژه
            های اداری و توسعه سرمایه های انسانی . انجام خدمات رفاهی برای پرسنل
            در شرکت ها و سازمان های دولتی و خصوصی به صورت حضوری و مجازی . مشاوره
            و تامین کلیه تجهیزات و اقلام مورد نیاز سازمان ها و شرکت ها . مراجعه
            به ادارات و سازمان های دولتی و خصوصی جهت اخذ پروانه و کلیه اسناد
            ثبتی و غیره . تامین نیروی انسانی سازمان ها . ارائه خدمات مدیریتی ،
            مشاوره ای در جهت توسعه فعالیت های کارآفرینی . تکثیر و تولید محتوا در
            قالب متون و الکترونیکی . انتقال و ارائه تجربیات دانش فنی .
          </p>
          <hr />
          <div className="set-row-middle justify-content-around mb-3">
            <h4>ارتباط با ما</h4>
          </div>
          <ul className="unstyled-list set-row-middle justify-content-around">
            <li>
              <a
                href="#!"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Telegram size={"2rem"} />
                تلگرام
              </a>
            </li>
            <li>
              <a
                href="#!"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Instagram size={"2rem"} color={"#833AB4"} />
                اینستاگرام
              </a>
            </li>
            <li>
              <a
                href="mailto:info@namapardazamir.ir"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Envelope size={"2rem"} color={"#DB4437"} />
                ایمیل
              </a>
            </li>
            <li>
              <a
                href="#!"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Whatsapp size={"2rem"} color={"#4FCE5D"} />
                واتساپ
              </a>
            </li>
            <li>
              <a
                href="http://namapardazamir.ir"
                target="_blank"
                rel="noreferrer"
                className="set-column-middle"
              >
                <Globe2 size={"2rem"} />
                وبسایت ما
              </a>
            </li>
            <li>
              <strong className="set-column-middle">
                <Telephone size={"2rem"} color={"#000"} />
                <span>شماره تماس</span>
                <span>0912-608-8954</span>
              </strong>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)} variant={"danger"}>
            بستن
          </Button>
        </Modal.Footer>
      </Portal>
    </div>
  );
}

export default Dashboard;
