import {
    TOGGLE_MODAL_LOADING, SET_COUNTERS,
    TOGGLE_LOADING, SET_PROFILE,SET_DATA_FROM_LOGIN
} from "./types";
const initialState = {
    profile: "",
    userCount: 0,
    docCount: 0,
    bargCount: 0,
    modalLoading: false,
    Loading: false,
};
const myReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_FROM_LOGIN:
            localStorage.setItem("_data", JSON.stringify({ isAuth: true, ...action.payload }));
            return {
                ...state,
                bargCount: action.payload.bargCount,
                docCount: action.payload.docCount,
                userCount: action.payload.userCount,
            };
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload,
            };
        case SET_COUNTERS:
            let storage = JSON.parse(localStorage.getItem("_data"));
            let newStorage = JSON.stringify({
                ...storage,
                bargCount: action.payload.bargCount,
                docCount: action.payload.docCount,
                userCount: action.payload.userCount,
            });
            localStorage.setItem("_data", newStorage)
            return {
                ...state,
                bargCount: action.payload.bargCount,
                docCount: action.payload.docCount,
                userCount: action.payload.userCount,
            };
        case TOGGLE_LOADING:
            return {
                ...state,
                Loading: action.payload,
            };
        case TOGGLE_MODAL_LOADING:
            return {
                ...state,
                modalLoading: action.payload,
            };
        default:
            return state;
    }
};

export default myReducer;
