import {
  TOGGLE_MODAL_LOADING, SET_COUNTERS,
  TOGGLE_LOADING, SET_PROFILE,SET_DATA_FROM_LOGIN
} from "./types";
export const setDataFromLogin = (params) => {
  return {
    type: SET_DATA_FROM_LOGIN,
    payload: params,
  };
};
export const setImageProfile = (params) => {
  return {
    type: SET_PROFILE,
    payload: params,
  };
};
export const setCounters = (params) => {
  return {
    type: SET_COUNTERS,
    payload: params,
  };
};
export const toggleModalLoading = (params) => {
  return {
    type: TOGGLE_MODAL_LOADING,
    payload: params,
  };
};
export const toggleLoading = (params) => {
  return {
    type: TOGGLE_LOADING,
    payload: params,
  };
};


