export const SITEURL = "https://mali.namapardazamir.ir";
export const BASEURL = "https://mali.namapardazamir.ir/service";
export const IMAGEURLS = "https://mali.namapardazamir.ir/upload/";

// export const SITEURL = "/";
// export const BASEURL = "/service";
// export const IMAGEURLS = "/upload/";

// export const SITEURL = "http://localhost:5000";
// export const BASEURL = "http://localhost:5000/service";
// export const IMAGEURLS = "http://localhost:5000/upload/";