import React from "react";
import "./Wizard.scss";
import { Row, Col } from "react-bootstrap";

function Wizard(props) {
  return (
    <div className="Wizard-wrapper">
      <div className="h-100">
        <Row>
          <Col sm={12} lg={4}>
            <div
              className={`wizard-header cpointer set-column-middle py-4 ${
                props.active === 1 ? "active" : ""
              }`}
              onClick={() => {
                props.setActiveWizard(1);
              }}
            >
              <strong>پیکربندی اولیه</strong>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div
              className={`wizard-header cpointer set-column-middle py-4 ${
                props.active === 2 ? "active" : ""
              }`}
              onClick={() => {
                props.setActiveWizard(2);
              }}
            >
              <strong>اسناد پرونده</strong>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div
              className={`wizard-header cpointer set-column-middle py-4 ${
                props.active === 3 ? "active" : ""
              }`}
              onClick={() => {
                props.setActiveWizard(3);
              }}
            >
              <strong>پیوست</strong>
            </div>
          </Col>
        </Row>
        <hr />
        {props.children}
      </div>
    </div>
  );
}

export default Wizard;
