import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
function Portal(props) {
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.children}
    </Modal>,
    document.getElementById("modals-wrapper")
  );
}

export default Portal;
