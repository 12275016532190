import React, { useState } from "react";
import { Trash, PencilSquare, CheckLg, XLg } from "react-bootstrap-icons";
import { InputGroup, FormControl, Col } from "react-bootstrap";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
function EnumItem({ enumType, name, items }) {
  const [enumData, setEnumData] = useState({
    newEnumDataTitle: "",
    newEnumDataTitleErr: "",
    editedEnumDataTitle: "",
    editedEnumDataTitleErr: false,
    editMode: false,
    editedIdentity: "",
    list: items,
  });
  const saveEnum = () => {
    let myList = [];
    if (enumData.newEnumDataTitle === "") {
      setEnumData({
        ...enumData,
        newEnumDataTitleErr: `مقدار فیلد ${name} جدید نمیتواند خالی باشد`,
      });
    } else {
      let newD = [
        {
          key: enumData.list.length + 1,
          value: enumData.newEnumDataTitle,
        },
      ];
      myList = [...enumData.list, ...newD];
      AxiosInterceptor.post("/Save", {
        list: JSON.stringify(myList),
        type: enumType,
      }).then((response) => {
        if (!response.data.includes("error")) {
          setEnumData({
            ...enumData,
            newEnumDataTitle: "",
            newEnumDataTitleErr: "",
            list: response.data,
          });
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
      });
    }
  };
  const deleteEnum = (dckey, dcValue) => {
    swal({
      title: "مطمئن هستید؟",
      text: " آیا از حذف " + dcValue + " اطمینان دارید؟ ",
      icon: "warning",
      buttons: ["خیر", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let counter = 1;
        let myList = [];
        enumData.list.forEach((dc) => {
          if (dc.key !== dckey) {
            dc.key = counter;
            counter++;
            myList = [...myList, dc];
          }
        });
        AxiosInterceptor.post("/Save", {
          list: JSON.stringify(myList),
          type: enumType,
        }).then((response) => {
          if (!response.data.includes("error")) {
            setEnumData({
              ...enumData,
              newEnumDataTitle: "",
              newEnumDataTitleErr: "",
              list: response.data,
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const updateEnum = () => {
    let myList = [];
    myList = enumData.list;
    if (enumData.editedEnumDataTitle === "") {
      setEnumData({
        ...enumData,
        editedEnumDataTitleErr: true,
      });
    } else {
      myList.forEach((el) => {
        if (el.key === enumData.editedIdentity) {
          el.value = enumData.editedEnumDataTitle;
        }
      });
      AxiosInterceptor.post("/Save", {
        list: JSON.stringify(myList),
        type: enumType,
      }).then((response) => {
        if (!response.data.includes("error")) {
          setEnumData((prevState) => ({
            ...prevState,
            editedEnumDataTitle: "",
            editedEnumDataTitleErr: false,
            list: response.data,
          }));
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        setEnumData((prevState) => ({
          ...prevState,
          editMode: false,
        }));
      });
    }
  };
  return (
    <Col sm={12} lg={6}>
      <div className="text-center">
        <h5>{name}</h5>
      </div>
      <div className="set-row-middle">
        <div className="input-wrapper">
          <InputGroup className="mb-2">
            <InputGroup.Text>{name + " جدید"}</InputGroup.Text>
            <FormControl
              type="text"
              className={enumData.newEnumDataTitleErr ? "errorBorder" : ""}
              name="newEnumDataTitle"
              value={enumData.newEnumDataTitle}
              onChange={(e) => {
                setEnumData({
                  ...enumData,
                  newEnumDataTitle: e.target.value,
                });
              }}
              autoComplete="new-password"
            />
          </InputGroup>
        </div>
        <button
          className="btn btn-success m-2 mt-0"
          onClick={() => saveEnum()}
        >
          افزودن+
        </button>
      </div>
      <p className="error text-center">{enumData.newEnumDataTitleErr}</p>
      <ul className="setting-list-group list-group px-0">
        {enumData.list.map((dc) => {
          return (
            <li className="list-group-item" id={dc.key} key={dc.key}>
              {enumData.editMode && enumData.editedIdentity === dc.key ? (
                <>
                  <input
                    type="text"
                    className={`text-center px-2 ${
                      enumData.editedEnumDataTitleErr ? "errorBorder" : ""
                    }`}
                    value={enumData.editedEnumDataTitle}
                    onChange={(e) => {
                      setEnumData((prevState) => ({
                        ...prevState,
                        editedEnumDataTitle: e.target.value,
                      }));
                    }}
                  />
                  <span
                    className="float-start me-2 cpointer"
                    onClick={() => {
                      setEnumData((prevState) => ({
                        ...prevState,
                        editMode: false,
                      }));
                    }}
                  >
                    <XLg color={"#DC3545"} />
                  </span>
                  <span
                    className="float-start cpointer"
                    onClick={() => {
                      updateEnum();
                    }}
                  >
                    <CheckLg color={"#28A745"} />
                  </span>
                </>
              ) : (
                <>
                  {dc.value}
                  <span
                    className="float-start me-2 cpointer"
                    onClick={() => {
                      deleteEnum(dc.key, dc.value);
                    }}
                  >
                    <Trash color={"#DC3545"} />
                  </span>
                  <span
                    className="float-start cpointer"
                    onClick={() => {
                      setEnumData((prevState) => ({
                        ...prevState,
                        editedEnumDataTitle: dc.value,
                        editMode: true,
                        editedIdentity: dc.key,
                      }));
                    }}
                  >
                    <PencilSquare color={"#FFC107"} />
                  </span>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </Col>
  );
}

export default EnumItem;
