import React, { useEffect, useRef } from "react";
import "./DashboardHome.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import cardSvg1 from "../../assets/images/cardSvg1.svg";
import cardSvg2 from "../../assets/images/cardSvg2.svg";
import cardSvg3 from "../../assets/images/cardSvg3.svg";
// import logo from "../../assets/images/logo.png";
import { useSelector } from "react-redux"
function DashboardHome() {
  const reduxState = useSelector((state) => state);

  const hoursRef = useRef(null);
  const minsRef = useRef(null);
  const secsRef = useRef(null);
  const intervalRef = useRef(null);
  const deg = 6;

  const clock = () => {
    let day = new Date();
    let hh = day.getHours() * 30;
    let mm = day.getMinutes() * deg;
    let ss = day.getSeconds() * deg;
    hoursRef.current.style.transform = `rotateZ(${hh + mm / 12}deg)`;
    minsRef.current.style.transform = `rotateZ(${mm}deg)`;
    secsRef.current.style.transform = `rotateZ(${ss}deg)`;
  };
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      clock();
    }, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Dashboard>
      <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div className="container-xl px-4">
          <div className="page-header-content pt-4">
            <div className="row align-items-center justify-content-between">
              <div className="col-12">
                <h1 className="page-header-title">
                  <div className="page-header-icon">
                    <i data-feather="activity"></i>
                  </div>
                  داشبورد
                </h1>
                <div className="page-header-subtitle">
                  به داشبورد مدیریت اسناد دیجیتال نماپرداز امیر خوش آمدید.
                </div>
                {/* <img id="logoInDashboard" src={logo} alt="logo" /> */}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container-xl px-4 mt-n10">
        <div className="row">
          <div className="col-xl-4 mb-4">
            <div className="card lift h-100">
              <div className="card-body d-flex justify-content-center flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ms-3">
                    <i
                      className="feather-xl text-primary mb-3"
                      data-feather="package"
                    ></i>
                    <h5>تعداد اسناد شما</h5>
                    <div className="text-muted text-center small">
                      {reduxState.docCount +
                        " از " +
                        JSON.parse(localStorage.getItem("_data")).maxRecord +
                        " "}
                      سند
                    </div>
                  </div>
                  <img src={cardSvg3} alt="..." style={{ width: "8rem" }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mb-4">
            <a className="card lift h-100" href="/createNewDoc">
              <div className="card-body d-flex justify-content-center flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ms-3">
                    <i
                      className="feather-xl text-secondary mb-3"
                      data-feather="book"
                    ></i>
                    <h5>ساخت سند جدید</h5>
                    <div className="text-muted small">
                      برای ساختن سند روی این بخش کلیک نمایید (یا از منو گزینه
                      سند جدید را انتخاب نمایید)
                    </div>
                  </div>
                  <img src={cardSvg1} alt="..." style={{ width: "8rem" }} />
                </div>
              </div>
            </a>
          </div>
          <div className="col-xl-4 mb-4">
            <div
              className="card lift h-100 cpointer"
              onClick={() => {
                document.getElementById("help").click();
              }}
            >
              <div className="card-body d-flex justify-content-center flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="ms-3">
                    <i
                      className="feather-xl text-green mb-3"
                      data-feather="layout"
                    ></i>
                    <h5>راهنمایی</h5>
                    <div className="text-muted small">
                      در صورت نیاز به راهنمایی چگونگی ساخت، ویرایش و یا حذف سند
                      روی این بخش کلیک نمایید.
                    </div>
                  </div>
                  <img src={cardSvg2} alt="..." style={{ width: "8rem" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-sm-12 set-row-middle">
            <div className="clock-wrapper">
              <div className="clock">
                <div className="hour">
                  <div className="hr" id="hr" ref={hoursRef}></div>
                </div>
                <div className="minute">
                  <div className="mn" id="mn" ref={minsRef}></div>
                </div>
                <div className="sec">
                  <div className="sc" id="sc" ref={secsRef}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default DashboardHome;
