import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import {
  Table,
  Col,
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import "./DocList.scss";
import {
  ChevronDown,
  PencilSquare,
  Trash,
  CheckCircleFill,
  InfoCircleFill,
  ArrowLeft,
  ArrowRight,
  ArrowLeftCircleFill,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { DateInput } from "react-hichestan-datetimepicker";
import Portal from "../../components/Portal/Portal";
import { Accordion, useAccordionButton, Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { toggleLoading, setCounters } from "../../redux/actions";
import { SITEURL } from "../../utils/Constants";
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);
  return (
    <button
      className="btn text-info float-start"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
function DocList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxState = useSelector((state) => state);
  const tableHeightRef = useRef(null);
  const [inputs, setInputs] = useState({
    folderNumber: "",
    docDate: "",
    docPrice: "",
    docMain: "",
    docSub: "",
    docTurn: "",
    binder: "",
    cover: "",
    page: "",
    docTitle: "",
    docName: "",
    docClass: "",
    projectName: "",
    sectionName: "",
  });
  const [partNames, setPartNames] = useState({
    newPartName: "",
    newPartNameErr: "",
    list: [],
  });
  const [maliYearModal, setMaliYearModal] = useState({
    maliYearName: "",
    maliYearNameErr: "",
    showModal: false,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    // min: 1,
    // max: 5,
    pointer: +localStorage.getItem("lastPaginatePointer") || 1,
    items: [],
    docNumberPerPage: "15",
    goTo: "",
  });

  const [docs, setDocs] = useState([]);
  const [reportListModalShow, setReportListModalShow] = useState(false);
  const [configModalShow, setConfigModalShow] = useState(false);
  const [saveConfigShow, setSaveConfigShow] = useState(false);
  const [chartModal, setChartModal] = useState({
    show: false,
    chartType: "",
    maliYear: "",
    maliYearErr: "",
    maliYearsArray: [],
    showChart: false,
    chart1Data: {},
    chart3Data: {},
  });
  const [configName, setConfigName] = useState({ name: "", nameErr: "" });
  const [reports, setReports] = useState([]);
  const [multipleSelectItems, setMultipleSelectItems] = useState({
    showList: false,
    selectedItems: [],
    nonSelectedItems: [
      { id: "1", value: "نام سند", name: "docName", type: "text" },
      { id: "2", value: "شماره فولدر", name: "folderNumber", type: "text" },
      { id: "3", value: "مرکز هزینه", name: "sectionName", type: "select" },
      { id: "4", value: "مبلغ سند به ریال", name: "docPrice", type: "text" },
      { id: "5", value: "اصلی", name: "docMain", type: "text" },
      { id: "6", value: "فرعی", name: "docSub", type: "text" },
      { id: "7", value: "عطف", name: "docTurn", type: "text" },
      { id: "8", value: "زونکن", name: "binder", type: "text" },
      { id: "9", value: "برگ", name: "page", type: "text" },
      { id: "10", value: "جلد", name: "cover", type: "text" },
      { id: "11", value: "موضوع سند", name: "docTitle", type: "text" },
      { id: "12", value: "کلاسه پرونده", name: "docClass", type: "text" },
      { id: "13", value: "نام پروژه", name: "projectName", type: "text" },
      { id: "14", value: "تاریخ سند", name: "docDate", type: "date" },
      { id: "15", value: "نام پیمانکار", name: "peymankarName", type: "text" },
    ],
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const onChangeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const getStaticData = (type) => {
    AxiosInterceptor.post("/Get", { type: type }).then((response) => {
      if (!response.data.includes("error")) {
        if (type === "DocType") {
          // setDocTypes({
          //   newDocType: "",
          //   newDocTypeErr: "",
          //   list: response.data,
          // });
        } else if (type === "PartName") {
          setPartNames({
            newPartName: "",
            newPartNameErr: "",
            list: response.data,
          });
        }
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
    });
  };
  const editDoc = (docId) => {
    navigate("/editDocument/" + docId);
  };
  const getAllDocs = (page, count) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/List", {
      page: paginationFilter.pointer.toString(),
      take: paginationFilter.docNumberPerPage,
    }).then((response) => {
      if (
        typeof response.data === "string" &&
        response.data.includes("error")
      ) {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
        setDocs([]);
      } else {
        setDocs(response.data);
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const getAllReports = () => {
    AxiosInterceptor.post("/Get", { type: "Reports" }).then((response) => {
      if (!response.data.includes("error")) {
        setReports(response.data);
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
    });
  };
  const deleteDoc = (docId) => {
    let alertInput = document.createElement("div");
    ReactDOM.render(
      <Form.Control id="adminPassword" name="adminPassword" type="password" />,
      alertInput
    );
    let el = alertInput.firstChild;
    swal("برای حذف سند باید کلمه عبور ادمین را وارد نمایید.", {
      content: el,
      // type : "password",
      buttons: ["لغو", "حذف"],
    }).then((value) => {
      if (value) {
        AxiosInterceptor.post("/Delete", {
          id: docId,
          Password: document.querySelector("#adminPassword").value,
        }).then((response) => {
          if (
            typeof response.data === "string" &&
            response.data.includes("error")
          ) {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          } else if (response.data) {
            getAllDocs();
            swal({
              icon: "success",
              title: "موفقیت",
              text: "پرونده شما با موفقیت حذف گردید",
              button: "متوجه شدم",
            });
            dispatch(
              setCounters({
                bargCount: reduxState.bargCount,
                userCount: reduxState.userCount,
                docCount: reduxState.docCount - 1,
              })
            );
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: "حذف پرونده با خطا مواجه شد",
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const exportExcel = (e) => {
    e.target.disabled = true;
    e.target.style.cursor = "wait";
    let mySearch = {};
    if (isFiltered) {
      if (inputs.docName !== "") {
        mySearch = {
          ...mySearch,
          DocName: inputs.docName,
        };
      }
      if (inputs.folderNumber !== "") {
        mySearch = {
          ...mySearch,
          FolderNumber: inputs.folderNumber,
        };
      }
      if (maliYearModal.maliYearName !== "") {
        mySearch = {
          ...mySearch,
          MaliYear: maliYearModal.maliYearName,
        };
      }
      if (inputs.docPrice !== "") {
        mySearch = {
          ...mySearch,
          DocPrice: inputs.docPrice,
        };
      }
      if (inputs.docMain !== "") {
        mySearch = {
          ...mySearch,
          MainNumber: inputs.docMain,
        };
      }
      if (inputs.docSub !== "") {
        mySearch = {
          ...mySearch,
          SubNumber: inputs.docSub,
        };
      }
      if (inputs.docTurn !== "") {
        mySearch = {
          ...mySearch,
          AtfNumber: inputs.docTurn,
        };
      }
      if (inputs.binder !== "") {
        mySearch = {
          ...mySearch,
          ZonkanNumber: inputs.binder,
        };
      }
      if (inputs.cover !== "") {
        mySearch = {
          ...mySearch,
          JeldNumber: inputs.cover,
        };
      }
      if (inputs.page !== "") {
        mySearch = {
          ...mySearch,
          BargNumber: inputs.page,
        };
      }
      if (inputs.docTitle !== "") {
        mySearch = {
          ...mySearch,
          DocSubject: inputs.docTitle,
        };
      }
      if (inputs.docClass !== "") {
        mySearch = {
          ...mySearch,
          DocClass: inputs.docClass,
        };
      }
      if (inputs.projectName !== "") {
        mySearch = {
          ...mySearch,
          ProjectName: inputs.projectName,
        };
      }
    }
    AxiosInterceptor.post("/ExportToExcel ", mySearch).then((response) => {
      if (
        typeof response.data === "string" &&
        response.data.includes("error")
      ) {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      } else {
        // console.log(SITEURL + response.data);
        window.open(SITEURL + response.data);
      } 
      e.target.disabled = false;
      e.target.style.cursor = "pointer";
    });
  };
  const submitReport = (rep) => {
    setReportListModalShow(false);
    setIsFiltered(true);
    let mySearch = {};
    if (JSON.parse(rep.value).docName !== "") {
      mySearch = {
        ...mySearch,
        DocName: JSON.parse(rep.value).docName,
      };
    }
    if (JSON.parse(rep.value).folderNumber !== "") {
      mySearch = {
        ...mySearch,
        FolderNumber: JSON.parse(rep.value).folderNumber,
      };
    }
    if (JSON.parse(rep.value).maliYear !== "") {
      mySearch = {
        ...mySearch,
        MaliYear: JSON.parse(rep.value).maliYear,
      };
    }
    if (JSON.parse(rep.value).docPrice !== "") {
      mySearch = {
        ...mySearch,
        DocPrice: JSON.parse(rep.value).docPrice,
      };
    }
    if (JSON.parse(rep.value).docMain !== "") {
      mySearch = {
        ...mySearch,
        MainNumber: JSON.parse(rep.value).docMain,
      };
    }
    if (JSON.parse(rep.value).docSub !== "") {
      mySearch = {
        ...mySearch,
        SubNumber: JSON.parse(rep.value).docSub,
      };
    }
    if (JSON.parse(rep.value).docTurn !== "") {
      mySearch = {
        ...mySearch,
        AtfNumber: JSON.parse(rep.value).docTurn,
      };
    }
    if (JSON.parse(rep.value).binder !== "") {
      mySearch = {
        ...mySearch,
        ZonkanNumber: JSON.parse(rep.value).binder,
      };
    }
    if (JSON.parse(rep.value).cover !== "") {
      mySearch = {
        ...mySearch,
        JeldNumber: JSON.parse(rep.value).cover,
      };
    }
    if (JSON.parse(rep.value).page !== "") {
      mySearch = {
        ...mySearch,
        BargNumber: JSON.parse(rep.value).page,
      };
    }
    if (JSON.parse(rep.value).docTitle !== "") {
      mySearch = {
        ...mySearch,
        DocSubject: JSON.parse(rep.value).docTitle,
      };
    }
    if (JSON.parse(rep.value).docClass !== "") {
      mySearch = {
        ...mySearch,
        DocClass: JSON.parse(rep.value).docClass,
      };
    }
    if (JSON.parse(rep.value).projectName !== "") {
      mySearch = {
        ...mySearch,
        ProjectName: JSON.parse(rep.value).projectName,
      };
    }
    AxiosInterceptor.post("/List", mySearch).then((response) => {
      if (
        typeof response.data === "string" &&
        response.data.includes("error")
      ) {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
        setDocs([]);
      } else if (response.data.length === 0) {
        swal({
          icon: "error",
          title: "خطا",
          text: "نتیجه ای برای این مقادیر یافت نشد",
          button: "متوجه شدم",
        });
        setDocs([]);
      } else {
        setDocs(response.data);
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const deleteReport = (rep) => {
    swal({
      title: "مطمئن هستید؟",
      text: " آیا از حذف گزارش " + rep.key + " اطمینان دارید؟ ",
      icon: "warning",
      buttons: ["خیر", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let myList = [];
        reports.forEach((report) => {
          if (report.key !== rep.key) {
            myList = [...myList, report];
          }
        });
        AxiosInterceptor.post("/Save", {
          list: JSON.stringify(myList),
          type: "Reports",
        }).then((response) => {
          if (!response.data.includes("error")) {
            setReports(response.data);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          }
        });
      }
    });
  };
  const saveConfig = () => {
    let myList = [];
    let hasError = false;
    if (configName.name === "") {
      setConfigName({
        ...configName,
        nameErr: "مقدار فیلد نام گزارش جدید نمیتواند خالی باشد",
      });
    } else {
      reports.forEach((r) => {
        if (r.key === configName.name) hasError = true;
      });
      if (hasError) {
        setConfigName({
          ...configName,
          nameErr: "نام گزارش نمیتواند تکراری باشد",
        });
      } else {
        let newC = [
          {
            key: configName.name,
            value: JSON.stringify({
              // uploadDate: "",
              folderNumber: inputs.folderNumber,
              // docDate: "",
              maliYear: maliYearModal.maliYearName,
              docPrice: inputs.docPrice,
              docMain: inputs.docMain,
              docSub: inputs.docSub,
              docTurn: inputs.docTurn,
              binder: inputs.binder,
              cover: inputs.cover,
              page: inputs.page,
              // docType: "",
              // sectionName: "",
              docTitle: inputs.docTitle,
              docName: inputs.docName,
              docClass: inputs.docClass,
              projectName: inputs.projectName,
            }),
          },
        ];
        myList = [...reports, ...newC];
        AxiosInterceptor.post("/Save", {
          list: JSON.stringify(myList),
          type: "Reports",
        }).then((response) => {
          setConfigName({ ...configName, nameErr: "" });
          setSaveConfigShow(false);
          resetConfigModal();
          if (!response.data.includes("error")) {
            swal({
              icon: "success",
              title: "موفقیت",
              text: "گزارش مورد نظر ذخیره گردید.\nاز طریق لیست گزارشات میتوانید همه گزارشات خود را مشاهده نمایید",
              button: "متوجه شدم",
            });
            setConfigName("");
            setReports(response.data);
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          }
        });
      }
    }
  };
  const submitConfig = () => {
    let mySearch = {};
    if (inputs.docName !== "") {
      mySearch = {
        ...mySearch,
        DocName: inputs.docName,
      };
    }
    if (inputs.folderNumber !== "") {
      mySearch = {
        ...mySearch,
        FolderNumber: inputs.folderNumber,
      };
    }
    if (maliYearModal.maliYearName !== "") {
      mySearch = {
        ...mySearch,
        MaliYear: maliYearModal.maliYearName,
      };
    }
    if (inputs.docPrice !== "") {
      mySearch = {
        ...mySearch,
        DocPrice: inputs.docPrice,
      };
    }
    if (inputs.docMain !== "") {
      mySearch = {
        ...mySearch,
        MainNumber: inputs.docMain,
      };
    }
    if (inputs.docSub !== "") {
      mySearch = {
        ...mySearch,
        SubNumber: inputs.docSub,
      };
    }
    if (inputs.docTurn !== "") {
      mySearch = {
        ...mySearch,
        AtfNumber: inputs.docTurn,
      };
    }
    if (inputs.binder !== "") {
      mySearch = {
        ...mySearch,
        ZonkanNumber: inputs.binder,
      };
    }
    if (inputs.cover !== "") {
      mySearch = {
        ...mySearch,
        JeldNumber: inputs.cover,
      };
    }
    if (inputs.page !== "") {
      mySearch = {
        ...mySearch,
        BargNumber: inputs.page,
      };
    }
    if (inputs.docTitle !== "") {
      mySearch = {
        ...mySearch,
        DocSubject: inputs.docTitle,
      };
    }
    if (inputs.docClass !== "") {
      mySearch = {
        ...mySearch,
        DocClass: inputs.docClass,
      };
    }
    if (inputs.projectName !== "") {
      mySearch = {
        ...mySearch,
        ProjectName: inputs.projectName,
      };
    }
    if (inputs.peymankarName !== "") {
      mySearch = {
        ...mySearch,
        PeymankarName: inputs.peymankarName,
      };
    }
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/List", mySearch).then((response) => {
      setIsFiltered(true);
      resetConfigModal();
      if (
        typeof response.data === "string" &&
        response.data.includes("error")
      ) {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
        setDocs([]);
      } else if (response.data.length === 0) {
        swal({
          icon: "error",
          title: "خطا",
          text: "نتیجه ای برای این مقادیر یافت نشد",
          button: "متوجه شدم",
        });
        setDocs([]);
      } else {
        setDocs(response.data);
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const removeFilter = () => {
    setIsFiltered(false);
    setInputs({
      folderNumber: "",
      docDate: "",
      docPrice: "",
      docMain: "",
      docSub: "",
      docTurn: "",
      binder: "",
      cover: "",
      page: "",
      docTitle: "",
      docName: "",
      docClass: "",
      projectName: "",
      sectionName: "",
    });
    getAllDocs();
  };
  const resetConfigModal = () => {
    setConfigModalShow(false);
    setMultipleSelectItems({
      showList: false,
      selectedItems: [],
      nonSelectedItems: [
        { id: "1", value: "نام سند", name: "docName", type: "text" },
        { id: "2", value: "شماره فولدر", name: "folderNumber", type: "text" },
        { id: "3", value: "مرکز هزینه", name: "maliYear", type: "select" },
        { id: "4", value: "مبلغ سند به ریال", name: "docPrice", type: "text" },
        { id: "5", value: "اصلی", name: "docMain", type: "text" },
        { id: "6", value: "فرعی", name: "docSub", type: "text" },
        { id: "7", value: "عطف", name: "docTurn", type: "text" },
        { id: "8", value: "زونکن", name: "binder", type: "text" },
        { id: "9", value: "برگ", name: "page", type: "text" },
        { id: "10", value: "جلد", name: "cover", type: "text" },
        { id: "11", value: "موضوع سند", name: "docTitle", type: "text" },
        { id: "12", value: "کلاسه پرونده", name: "docClass", type: "text" },
        { id: "13", value: "نام پروژه", name: "projectName", type: "text" },
        { id: "14", value: "تاریخ سند", name: "maliYear", type: "date" },
      ],
    });
  };
  const getThisPageDocs = (page) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/List", {
      page: page,
      take: paginationFilter.docNumberPerPage,
    }).then((response) => {
      if (
        typeof response.data === "string" &&
        response.data.includes("error")
      ) {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      } else {
        setDocs(response.data);
        localStorage.setItem("lastPaginatePointer", page);
        setPaginationFilter((prevState) => ({
          ...prevState,
          pointer: page,
          goTo: "",
        }));
      }
      if (response.status !== 200) {
        swal({
          icon: "error",
          title: "خطا",
          text: "لطفا بعدا تلاش کنید",
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  const getThisChart = (e, chartNumber) => {
    let hasErr = false;
    if (chartNumber === "2") {
      if (chartModal.maliYearsArray.length < 1) {
        setChartModal({
          ...chartModal,
          maliYearErr: "انتخاب حداقل یک سال مالی الزامی است",
        });
        hasErr = true;
      } else {
        setChartModal({
          ...chartModal,
          maliYearErr: "",
        });
      }
    } else {
      if (chartModal.maliYear === "") {
        setChartModal({
          ...chartModal,
          maliYearErr: "سال مالی نمیتواند خالی باشد",
        });
        hasErr = true;
      } else {
        setChartModal({
          ...chartModal,
          maliYearErr: "",
        });
      }
    }
    if (!hasErr) {
      e.target.disabled = true;
      e.target.style.cursor = "wait";
      if (chartNumber === "2") {
        let maliYearsArraySharpSeprator = "";
        for (let item = 0; item < chartModal.maliYearsArray.length; item++) {
          item + 1 === chartModal.maliYearsArray.length
            ? (maliYearsArraySharpSeprator += chartModal.maliYearsArray[item])
            : (maliYearsArraySharpSeprator +=
                chartModal.maliYearsArray[item] + "#");
        }
        AxiosInterceptor.post("/ChartApi", {
          Type: parseInt(chartNumber) - 1,
          MaliYear: maliYearsArraySharpSeprator,
        }).then((response) => {
          if (!response.data.includes("error")) {
            let mySeries = [];
            let yearString = "";
            for (let i = 0; i < chartModal.maliYearsArray.length; i++) {
              let myData = [];
              if (i + 1 === chartModal.maliYearsArray.length) {
                yearString += chartModal.maliYearsArray[i];
              } else {
                yearString += chartModal.maliYearsArray[i] + " , ";
              }
              response.data.forEach((dt) => {
                if (dt.item1 === parseInt(chartModal.maliYearsArray[i])) {
                  myData.push((parseInt(dt.item3) / 10000000000).toFixed(2));
                }
              });
              mySeries.push({
                name: "سال " + chartModal.maliYearsArray[i],
                data: myData,
              });
            }
            setChartModal({
              ...chartModal,
              showChart: true,
              chart3Data: {
                series: mySeries,
                options: {
                  chart: {
                    type: "bar",
                    height: 350,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "55%",
                      endingShape: "rounded",
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                  },
                  xaxis: {
                    categories: [
                      "فروردین",
                      "اردیبهشت",
                      "خرداد",
                      "تیر",
                      "مرداد",
                      "شهریور",
                      "مهر",
                      "آبان",
                      "آذر",
                      "دی",
                      "بهمن",
                      "اسفند",
                    ],
                    labels: {
                      style: {
                        fontFamily: "irSans",
                        fontSize: "14px",
                      },
                    },
                  },
                  yaxis: {
                    title: {
                      text: "میلیارد ریال",
                      offsetX: -55,
                      offsetY: 0,
                      style: {
                        fontFamily: "irSans",
                        fontSize: "17px",
                      },
                    },
                    labels: {
                      style: {
                        fontFamily: "irSans",
                        fontSize: "14px",
                      },
                    },
                  },
                  title: {
                    text: `نمودار هزینه سند - مقایسه ای برای سال های مالی ${yearString}`,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                      fontSize: "22px",
                      fontWeight: "bold",
                      fontFamily: "irSans",
                      color: "#263238",
                    },
                  },
                  fill: {
                    opacity: 1,
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return val + " میلیارد ریال ";
                      },
                    },
                  },
                },
              },
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          }
          e.target.disabled = false;
          e.target.style.cursor = "pointer";
        });
      } else if (chartNumber === "3") {
        AxiosInterceptor.post("/ChartApi", {
          Type: parseInt(chartNumber) - 1,
          MaliYear: chartModal.maliYear,
        }).then((response) => {
          if (!response.data.includes("error")) {
            let valArr = [];
            response.data.forEach((result) => {
              valArr.push(result.item2);
            });
            let keyArr = [];
            response.data.forEach((result) => {
              keyArr.push(result.item1);
            });
            setChartModal({
              ...chartModal,
              showChart: true,
              chart3Data: {
                series: [
                  {
                    data: valArr,
                  },
                ],
                options: {
                  chart: {
                    height: 350,
                    type: "bar",
                    events: {
                      click: function (chart, w, e) {
                        // console.log(chart, w, e)
                      },
                    },
                  },
                  // colors: colors,
                  plotOptions: {
                    bar: {
                      columnWidth: "45%",
                      distributed: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  xaxis: {
                    categories: keyArr,
                    labels: {
                      style: {
                        fontFamily: "irSans",
                        fontSize: "14px",
                      },
                    },
                  },
                  yaxis: {
                    labels: {
                      style: {
                        fontFamily: "irSans",
                        fontSize: "14px",
                      },
                    },
                  },
                  title: {
                    text: `نمودار مرکز هزینه برای سال مالی ${chartModal.maliYear}`,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                      fontSize: "22px",
                      fontWeight: "bold",
                      fontFamily: "irSans",
                      color: "#263238",
                    },
                  },
                },
              },
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          }
          e.target.disabled = false;
          e.target.style.cursor = "pointer";
        });
      } else {
        AxiosInterceptor.post("/ChartApi", {
          Type: parseInt(chartNumber) - 1,
          MaliYear: chartModal.maliYear,
        }).then((response) => {
          if (!response.data.includes("error")) {
            let newArr = [];
            response.data.forEach((result) => {
              newArr.push((parseInt(result.item2) / 10000000000).toFixed(2));
            });
            setChartModal({
              ...chartModal,
              showChart: true,
              chart1Data: {
                series: [
                  {
                    data: newArr,
                  },
                ],
                options: {
                  chart: {
                    type: "bar",
                    height: 350,
                  },
                  plotOptions: {
                    bar: {
                      // borderRadius: 4,
                      // horizontal: true,
                      columnWidth: "45%",
                      distributed: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  xaxis: {
                    categories: [
                      "فروردین",
                      "اردیبهشت",
                      "خرداد",
                      "تیر",
                      "مرداد",
                      "شهریور",
                      "مهر",
                      "آبان",
                      "آذر",
                      "دی",
                      "بهمن",
                      "اسفند",
                    ],
                    labels: {
                      style: {
                        fontFamily: "irSans",
                        fontSize: "14px",
                      },
                    },
                  },
                  yaxis: {
                    title: {
                      text: "میلیارد ریال",
                      offsetX: -55,
                      offsetY: 0,
                      style: {
                        fontFamily: "irSans",
                        fontSize: "17px",
                      },
                    },
                    labels: {
                      style: {
                        fontFamily: "irSans",
                        fontSize: "14px",
                      },
                    },
                  },
                  title: {
                    text: `نمودار هزینه سند برای سال مالی ${chartModal.maliYear}`,
                    align: "center",
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                      fontSize: "22px",
                      fontWeight: "bold",
                      fontFamily: "irSans",
                      color: "#263238",
                    },
                  },
                },
              },
            });
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data,
              button: "متوجه شدم",
            });
          }
          e.target.disabled = false;
          e.target.style.cursor = "pointer";
        });
      }
    }
  };
  useEffect(() => {
    getStaticData("PartName");
    getAllDocs();
    getAllReports();
  }, []);
  useEffect(() => {
    if (reduxState.docCount > 0) {
      let arr = [];
      let groupsNumber =
        parseInt(reduxState.docCount) /
        parseInt(paginationFilter.docNumberPerPage);
      let i = 1;
      while (groupsNumber > 0) {
        groupsNumber--;
        arr.push(i);
        i++;
      }
      setPaginationFilter({
        ...paginationFilter,
        items: arr,
      });
    }
    let wrapperHeight = document.querySelector(
      "#layoutSidenav_content"
    ).clientHeight;
    switch (paginationFilter.docNumberPerPage) {
      case "5":
        tableHeightRef.current.parentElement.style.maxHeight =
          parseInt(wrapperHeight) - 100 + "px";
        break;
      case "10":
        tableHeightRef.current.parentElement.style.maxHeight =
          parseInt(wrapperHeight) - 250 + "px";
        break;
      case "15":
        tableHeightRef.current.parentElement.style.maxHeight =
          parseInt(wrapperHeight) - 300 + "px";
        break;
      default:
        break;
    }
  }, [reduxState.docCount, paginationFilter.docNumberPerPage]);
  return (
    <Dashboard>
      <div className="docList-wrapper p-4">
        <div className="row mb-2">
          <Col
            sm={12}
            lg={4}
            className="d-flex align-item-center justify-content-start"
          >
            <h5 className="m-0 set-column-middle">
              مرور اسناد {isFiltered && "(فیلتر شده)"}
            </h5>
            {isFiltered && (
              <button className="btn btn-danger me-2" onClick={removeFilter}>
                حذف فیلتر
              </button>
            )}
          </Col>
          <Col
            sm={12}
            lg={8}
            className="d-flex align-item-center justify-content-end"
          >
            <button
              className="btn btn-secondary mx-2 text-white"
              onClick={() => {
                setChartModal({
                  ...chartModal,
                  show: true,
                });
              }}
            >
              نمودار ها
            </button>
            <button
              className="btn btn-success mx-2 text-white"
              onClick={() => {
                setMaliYearModal({
                  maliYearName: "",
                  maliYearNameErr: "",
                  showModal: true,
                });
              }}
            >
              جستجو/ فیلتر گزارشات بر اساس
            </button>
            <button
              className="btn btn-info mx-2 text-white"
              onClick={() => setReportListModalShow(true)}
            >
              لیست گزارشات قبلی
            </button>
            <button
              className="btn btn-warning mx-2 text-white"
              onClick={exportExcel}
            >
              خروجی اکسل
            </button>
          </Col>
        </div>
        <div className="row">
          <Table
            striped
            bordered
            hover
            responsive
            ref={tableHeightRef}
            style={{
              overflowY: "auto",
            }}
          >
            <thead className="gradiantBg text-white text-center">
              <tr>
                <th scope="col" className="text-nowrap">
                  ردیف
                </th>
                <th scope="col" className="text-nowrap">
                  نام سند
                </th>
                <th scope="col" className="text-nowrap">
                  تاریخ بارگذاری
                </th>
                <th scope="col" className="text-nowrap">
                  شماره فولدر
                </th>
                <th scope="col" className="text-nowrap">
                  تاریخ سند
                </th>
                <th scope="col" className="text-nowrap">
                  سال مالی
                </th>
                <th scope="col" className="text-nowrap">
                  مبلغ سند - ریال
                </th>
                <th scope="col" className="text-nowrap">
                  اصلی
                </th>
                <th scope="col" className="text-nowrap">
                  فرعی
                </th>
                <th scope="col" className="text-nowrap">
                  عطف
                </th>
                <th scope="col" className="text-nowrap">
                  زونکن
                </th>
                <th scope="col" className="text-nowrap">
                  جلد
                </th>
                <th scope="col" className="text-nowrap">
                  برگ
                </th>
                <th scope="col" className="text-nowrap">
                  نوع سند
                </th>
                <th scope="col" className="text-nowrap">
                  نام قسمت
                </th>
                <th scope="col" className="text-nowrap">
                  موضوع سند
                </th>
                <th scope="col" className="text-nowrap">
                  کلاسه پرونده
                </th>
                <th scope="col" className="text-nowrap">
                  نام پیمانکار
                </th>
                <th scope="col" className="text-nowrap">
                  نام پروژه
                </th>
                <th scope="col" className="text-nowrap">
                  تاریخ اسکن
                </th>
                <th scope="col" className="text-nowrap">
                  کاربر اسکن
                </th>
                <th scope="col" className="text-nowrap">
                  عملیات
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {docs.length > 0 ? (
                docs.map((doc, index) => {
                  return (
                    <tr key={index} id={doc.nDocId}>
                      <td className="text-nowrap">
                        {parseInt(paginationFilter.pointer) *
                          parseInt(paginationFilter.docNumberPerPage) +
                          (index +
                            1 -
                            parseInt(paginationFilter.docNumberPerPage))}
                      </td>
                      <td className="text-nowrap">{doc.docName}</td>
                      <td className="text-nowrap">
                        {doc.insertDateTimeString}
                      </td>
                      <td className="text-nowrap">{doc.folderNumber}</td>
                      <td className="text-nowrap">{doc.docDatePersian}</td>
                      <td className="text-nowrap">{doc.maliYear}</td>
                      <td className="text-nowrap">
                        {doc.docPrice
                          ? new Intl.NumberFormat().format(
                              parseInt(doc.docPrice)
                            )
                          : 0}
                      </td>
                      <td className="text-nowrap">{doc.mainNumber}</td>
                      <td className="text-nowrap">{doc.subNumber}</td>
                      <td className="text-nowrap">{doc.atfNumber}</td>
                      <td className="text-nowrap">{doc.zonkanNumber}</td>
                      <td className="text-nowrap">{doc.jeldNumber}</td>
                      <td className="text-nowrap">{doc.bargNumber}</td>
                      <td className="text-nowrap">{doc.docTypeString}</td>
                      <td className="text-nowrap">{doc.partNameString}</td>
                      <td className="text-nowrap">{doc.docSubject}</td>
                      <td className="text-nowrap">{doc.docClass}</td>
                      <td className="text-nowrap">{doc.peymankarName}</td>
                      <td className="text-nowrap">{doc.projectName}</td>
                      <td className="text-nowrap">{doc.scanDatetimePersian}</td>
                      <td className="text-nowrap">{doc.scanPersonName}</td>
                      <td className="set-row-middle">
                        <i
                          className="mx-2 cpointer"
                          onClick={() => {
                            deleteDoc(doc.nDocId);
                          }}
                        >
                          <Trash className="text-danger" size={"1.2rem"} />
                        </i>
                        <i
                          className="mx-2 cpointer"
                          onClick={() => {
                            editDoc(doc.nDocId);
                          }}
                        >
                          <PencilSquare
                            className="text-warning"
                            size={"1.2rem"}
                          />
                        </i>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={21}>لیست اسناد خالی میباشد</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="row">
          <div className="col-sm-12 px-3 py-3 set-row-middle position-relative">
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", right: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>برو به صفحه</InputGroup.Text>
                <FormControl
                  name="goTo"
                  type="number"
                  style={{ maxWidth: "70px" }}
                  value={paginationFilter.goTo}
                  onChange={(e) => {
                    setPaginationFilter({
                      ...paginationFilter,
                      goTo: e.target.value,
                    });
                  }}
                />
                <button
                  className="btn gradiantBg"
                  onClick={() => {
                    if (paginationFilter.goTo !== "") {
                      getThisPageDocs(parseInt(paginationFilter.goTo));
                    }
                  }}
                >
                  <ArrowLeftCircleFill size="1.3em" color="#fff" />
                </button>
              </Form.Group>
            </div>
            <ul
              id="paginationList"
              className="m-0 p-0 list-unstyled set-row-middle flex-wrap"
            >
              <li
                className="ms-1 cpointer"
                onClick={() => {
                  if (paginationFilter.pointer !== paginationFilter.items[0]) {
                    getThisPageDocs(paginationFilter.pointer - 1);
                    // not arrive to first item
                    // setPaginationFilter((prevState) => ({
                    //   ...prevState,
                    //   pointer: prevState.pointer - 1,
                    // }));
                  }
                }}
              >
                <ArrowRight size="1.3em" />
              </li>
              {paginationFilter.pointer !== 1 && (
                <li
                  className="paginationItems"
                  onClick={() => {
                    getThisPageDocs(1);
                  }}
                >
                  1
                </li>
              )}
              {paginationFilter.pointer > 2 && (
                <li>
                  <strong>.....</strong>
                </li>
              )}
              {paginationFilter.items.map((pa, index) => {
                return index + 1 === paginationFilter.pointer ||
                  (index <= paginationFilter.pointer + 1 &&
                    index + 1 >= paginationFilter.pointer) ||
                  index + 1 ===
                    paginationFilter.items[
                      paginationFilter.items.length - 1
                    ] ? (
                  <li
                    key={index}
                    className={`paginationItems ${
                      index + 1 === paginationFilter.pointer
                        ? "paginationItemActive"
                        : ""
                    }`}
                    onClick={() => {
                      getThisPageDocs(pa);
                    }}
                  >
                    {pa}
                  </li>
                ) : index + 1 ===
                  paginationFilter.items[paginationFilter.items.length - 2] ? (
                  <React.Fragment key={index}>
                    <li>
                      <strong>.....</strong>
                    </li>
                    <li
                      key={index}
                      className={`paginationItems ${
                        index + 1 === paginationFilter.pointer
                          ? "paginationItemActive"
                          : ""
                      }`}
                      onClick={() => {
                        getThisPageDocs(pa);
                      }}
                    >
                      {pa}
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}> </React.Fragment>
                );
              })}

              <li
                className="me-1 cpointer"
                onClick={() => {
                  if (
                    paginationFilter.pointer !==
                    paginationFilter.items[paginationFilter.items.length - 1]
                  ) {
                    getThisPageDocs(paginationFilter.pointer + 1);
                  }
                }}
              >
                <ArrowLeft size="1.3em" />
              </li>
            </ul>
            <div
              className="input-wrapper flex-nowrap"
              style={{ position: "absolute", left: "5px" }}
            >
              <Form.Group className="set-row-middle">
                <InputGroup.Text>تعداد سند در هر صفحه</InputGroup.Text>
                <Form.Select
                  value={paginationFilter.docNumberPerPage}
                  name="docNumberPerPage"
                  id="docNumberPerPage"
                  onChange={(e) => {
                    let myTake = e.target.value;
                    dispatch(toggleLoading(true));
                    AxiosInterceptor.post("/List", {
                      page: "1",
                      take: myTake,
                    }).then((response) => {
                      if (
                        typeof response.data === "string" &&
                        response.data.includes("error")
                      ) {
                        swal({
                          icon: "error",
                          title: "خطا",
                          text: response.data,
                          button: "متوجه شدم",
                        });
                      } else {
                        setDocs(response.data);
                        setPaginationFilter((prevState) => ({
                          ...prevState,
                          docNumberPerPage: myTake,
                          pointer: 1,
                        }));
                      }
                      if (response.status !== 200) {
                        swal({
                          icon: "error",
                          title: "خطا",
                          text: "لطفا بعدا تلاش کنید",
                          button: "متوجه شدم",
                        });
                      }
                      dispatch(toggleLoading(false));
                    });
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </div>
        <Portal show={chartModal.show}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              نمودار ها و آمار
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <>
                {chartModal.chartType === "" ? (
                  <div className="col-sm-7 m-auto">
                    <h5 className="text-center">نوع نمودار را انتخاب نمایید</h5>
                    <br />
                    <div className="row d-flex justify-content-around">
                      <Button
                        style={{ width: "fit-content" }}
                        onClick={() => {
                          setChartModal({ ...chartModal, chartType: "1" });
                        }}
                      >
                        نمودار هزینه سند
                      </Button>
                      <Button
                        style={{ width: "fit-content" }}
                        onClick={() => {
                          setChartModal({ ...chartModal, chartType: "2" });
                        }}
                      >
                        نمودار هزینه سند - مقایسه ای
                      </Button>
                      <Button
                        style={{ width: "fit-content" }}
                        onClick={() => {
                          setChartModal({ ...chartModal, chartType: "3" });
                        }}
                      >
                        نمودار مرکز هزینه
                      </Button>
                    </div>
                  </div>
                ) : chartModal.chartType === "1" ? (
                  <>
                    {chartModal.showChart ? (
                      <>
                        <div id="chart">
                          <Chart
                            options={chartModal.chart1Data.options}
                            series={chartModal.chart1Data.series}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="col-sm-4 m-auto set-column-middle">
                        <p className="text-center">سال مالی را انتخاب نمایید</p>
                        <InputGroup className="mb-1 input-wrapper">
                          <InputGroup.Text>سال مالی</InputGroup.Text>
                          <FormControl
                            name="maliYear"
                            id="maliYear"
                            type="number"
                            value={chartModal.maliYear}
                            className={
                              chartModal.maliYearErr ? "errorBorder" : ""
                            }
                            onChange={(e) => {
                              if (e.target.value.length <= 4) {
                                setChartModal({
                                  ...chartModal,
                                  maliYear: e.target.value,
                                });
                              }
                            }}
                          />
                        </InputGroup>
                        <p className="text-center error">
                          {chartModal.maliYearErr}
                        </p>
                        <Button
                          style={{ width: "fit-content" }}
                          onClick={(e) => {
                            getThisChart(e, "1");
                          }}
                        >
                          ثبت و ادامه
                        </Button>
                      </div>
                    )}
                  </>
                ) : chartModal.chartType === "2" ? (
                  <>
                    {chartModal.showChart ? (
                      <>
                        <div id="chart">
                          <Chart
                            options={chartModal.chart3Data.options}
                            series={chartModal.chart3Data.series}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="col-sm-4 m-auto set-column-middle">
                        <p className="text-center">
                          سال های مالی را انتخاب نمایید
                        </p>
                        <InputGroup className="mb-1 input-wrapper">
                          <InputGroup.Text>سال مالی</InputGroup.Text>
                          <FormControl
                            name="maliYear"
                            id="maliYear"
                            type="number"
                            value={chartModal.maliYear}
                            className={
                              chartModal.maliYearErr ? "errorBorder" : ""
                            }
                            onChange={(e) => {
                              if (e.target.value.length <= 4) {
                                setChartModal({
                                  ...chartModal,
                                  maliYear: e.target.value,
                                });
                              }
                            }}
                          />
                          <Button
                            className="me-2"
                            style={{ width: "fit-content" }}
                            variant={"success"}
                            onClick={() => {
                              if (chartModal.maliYear === "") {
                                setChartModal({
                                  ...chartModal,
                                  maliYearErr: "سال مالی نمیتواند خالی باشد",
                                });
                              } else {
                                let newArr = chartModal.maliYearsArray;
                                let errCount = 0;
                                chartModal.maliYearsArray.forEach((my) => {
                                  if (chartModal.maliYear === my) {
                                    setChartModal({
                                      ...chartModal,
                                      maliYearErr:
                                        "سال مالی نمیتواند تکراری باشد",
                                    });
                                    errCount++;
                                  }
                                });
                                if (errCount === 0) {
                                  newArr.push(chartModal.maliYear);
                                  setChartModal({
                                    ...chartModal,
                                    maliYear: "",
                                    maliYearErr: "",
                                    maliYearsArray: newArr,
                                  });
                                }
                              }
                            }}
                          >
                            افزودن به لیست سال ها
                          </Button>
                        </InputGroup>
                        <p className="text-center error">
                          {chartModal.maliYearErr}
                        </p>
                        {chartModal.maliYearsArray.length > 0 ? (
                          <>
                            <span>لیست سال‌های انتخاب شده : </span>
                            <ul
                              className="list-unstyled set-row-middle justify-content-around flex-wrap p-2 mb-2 w-100"
                              style={{
                                border: "1px dashed #000",
                                borderRadius: "4px",
                              }}
                            >
                              {chartModal.maliYearsArray.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="bg-info text-white p-1"
                                    style={{ borderRadius: "4px" }}
                                  >
                                    <strong>{item}</strong>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        ) : (
                          <span className="mb-2">
                            لیست سال‌های مالی خالی میباشد
                          </span>
                        )}
                        <Button
                          style={{ width: "fit-content" }}
                          onClick={(e) => {
                            getThisChart(e, "2");
                          }}
                        >
                          ثبت و ادامه
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {chartModal.showChart ? (
                      <>
                        <div id="chart">
                          <Chart
                            options={chartModal.chart3Data.options}
                            series={chartModal.chart3Data.series}
                            type="bar"
                            height={350}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="col-sm-4 m-auto set-column-middle">
                        <p className="text-center">سال مالی را انتخاب نمایید</p>
                        <InputGroup className="mb-1 input-wrapper">
                          <InputGroup.Text>سال مالی</InputGroup.Text>
                          <FormControl
                            name="maliYear"
                            id="maliYear"
                            type="number"
                            value={chartModal.maliYear}
                            className={
                              chartModal.maliYearErr ? "errorBorder" : ""
                            }
                            onChange={(e) => {
                              if (e.target.value.length <= 4) {
                                setChartModal({
                                  ...chartModal,
                                  maliYear: e.target.value,
                                });
                              }
                            }}
                          />
                        </InputGroup>
                        <p className="text-center error">
                          {chartModal.maliYearErr}
                        </p>
                        <Button
                          style={{ width: "fit-content" }}
                          onClick={(e) => {
                            getThisChart(e, "3");
                          }}
                        >
                          ثبت و ادامه
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {chartModal.chartType !== "" && (
              <Button
                onClick={() => {
                  setChartModal({
                    ...chartModal,
                    chartType: "",
                    maliYear: "",
                    maliYearErr: "",
                    showChart: false,
                    maliYearsArray: [],
                  });
                }}
              >
                بازگشت به لیست نمودار ها
              </Button>
            )}
            <Button
              onClick={() => {
                setChartModal({
                  ...chartModal,
                  show: false,
                  chartType: "",
                  maliYear: "",
                  maliYearErr: "",
                  showChart: false,
                  maliYearsArray: [],
                });
              }}
              variant={"danger"}
            >
              بستن
            </Button>
          </Modal.Footer>
        </Portal>
        <Portal show={reportListModalShow}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              گزارشات قبلی
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                {reports.length > 0 ? (
                  <Accordion defaultActiveKey="0">
                    {reports.map((rep, index) => {
                      return (
                        <Card key={index}>
                          <Card.Header>
                            {rep.key}
                            <div className="float-start">
                              <button
                                className="btn text-success mx-1"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  submitReport(rep);
                                }}
                              >
                                اعمال گزارش
                                <CheckCircleFill />
                              </button>
                              <button
                                className="btn mx-1 text-danger"
                                onClick={() => {
                                  deleteReport(rep);
                                }}
                              >
                                حذف
                                <Trash />
                              </button>

                              <CustomToggle eventKey={index}>
                                جزئیات گزارش
                                <InfoCircleFill />
                              </CustomToggle>
                            </div>
                          </Card.Header>
                          <Accordion.Collapse eventKey={index}>
                            <Card.Body>
                              <div className="row">
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  نام سند :
                                  {JSON.parse(rep.value).docName
                                    ? JSON.parse(rep.value).docName
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  تاریخ بارگذاری :
                                  {JSON.parse(rep.value).uploadDate
                                    ? JSON.parse(rep.value).uploadDate
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  شماره فولدر :
                                  {JSON.parse(rep.value).folderNumber
                                    ? JSON.parse(rep.value).folderNumber
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  سال مالی :
                                  {JSON.parse(rep.value).maliYear
                                    ? JSON.parse(rep.value).maliYear
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  مبلع سند به ریال :
                                  {JSON.parse(rep.value).docPrice
                                    ? JSON.parse(rep.value).docPrice
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap ">
                                  تاریخ سند :
                                  {JSON.parse(rep.value).docDate
                                    ? JSON.parse(rep.value).docDate
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  اصلی :
                                  {JSON.parse(rep.value).docMain
                                    ? JSON.parse(rep.value).docMain
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  فرعی :
                                  {JSON.parse(rep.value).docSub
                                    ? JSON.parse(rep.value).docSub
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  عطف :
                                  {JSON.parse(rep.value).docTurn
                                    ? JSON.parse(rep.value).docTurn
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  زونکن :
                                  {JSON.parse(rep.value).binder
                                    ? JSON.parse(rep.value).binder
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  جلد :
                                  {JSON.parse(rep.value).cover
                                    ? JSON.parse(rep.value).cover
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  برگ :
                                  {JSON.parse(rep.value).page
                                    ? JSON.parse(rep.value).page
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  نوع سند :
                                  {JSON.parse(rep.value).docType
                                    ? JSON.parse(rep.value).docType
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  نام قسمت :
                                  {JSON.parse(rep.value).sectionName
                                    ? JSON.parse(rep.value).sectionName
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  موضوع سند :
                                  {JSON.parse(rep.value).docTitle
                                    ? JSON.parse(rep.value).docTitle
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  کلاسه پرونده :
                                  {JSON.parse(rep.value).docClass
                                    ? JSON.parse(rep.value).docClass
                                    : "  "}
                                </div>
                                <div className="col accInfoStyle m-1 text-nowrap">
                                  نام پروژه :
                                  {JSON.parse(rep.value).projectName
                                    ? JSON.parse(rep.value).projectNameF
                                    : "  "}
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                ) : (
                  <h5 className="w-100 text-center">موردی یافت نشد !</h5>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setReportListModalShow(false)}
              variant={"danger"}
            >
              بستن
            </Button>
          </Modal.Footer>
        </Portal>
        <Portal show={maliYearModal.showModal}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              تعیین سال مالی
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                <h5 className="text-center">
                  ابتدا سال مالی مورد نظر خود را وارد نمایید
                </h5>
              </div>
            </div>
            <div className="row set-row-middle">
              <div className="col-sm-3">
                <div>
                  <InputGroup className="mb-1 input-wrapper">
                    <InputGroup.Text>سال مالی</InputGroup.Text>
                    <FormControl
                      name="maliYearName"
                      id="maliYearName"
                      type="number"
                      value={maliYearModal.maliYearName}
                      className={
                        maliYearModal.maliYearNameErr ? "errorBorder" : ""
                      }
                      onChange={(e) => {
                        if (e.target.value.length <= 4) {
                          setMaliYearModal({
                            ...maliYearModal,
                            maliYearName: e.target.value,
                          });
                        }
                      }}
                    />
                  </InputGroup>
                  <strong className="error">
                    {maliYearModal.maliYearNameErr}
                  </strong>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 set-row-middle mt-2">
                <Button
                  onClick={() => {
                    // if (maliYearModal.maliYearName === "") {
                    //   setMaliYearModal({
                    //     ...maliYearModal,
                    //     maliYearNameErr: "تعیین سال مالی الزامی است",
                    //   });
                    // } else {
                    //   setConfigModalShow(true);
                    //   setInputs({
                    //     folderNumber: "",
                    //     docDate: "",
                    //     docPrice: "",
                    //     docMain: "",
                    //     docSub: "",
                    //     docTurn: "",
                    //     binder: "",
                    //     cover: "",
                    //     page: "",
                    //     docTitle: "",
                    //     docName: "",
                    //     docClass: "",
                    //     projectName: "",
                    //     sectionName: "",
                    //   });
                    //   setMaliYearModal({
                    //     ...maliYearModal,
                    //     showModal: false,
                    //   });
                    // }
                    setConfigModalShow(true);
                    setInputs({
                      folderNumber: "",
                      docDate: "",
                      docPrice: "",
                      docMain: "",
                      docSub: "",
                      docTurn: "",
                      binder: "",
                      cover: "",
                      page: "",
                      docTitle: "",
                      docName: "",
                      docClass: "",
                      projectName: "",
                      sectionName: "",
                    });
                    setMaliYearModal({
                      ...maliYearModal,
                      showModal: false,
                    });
                  }}
                  variant="success"
                >
                  ثبت و ادامه
                </Button>
              </div>
              {!maliYearModal.maliYearName && (
                <div className="col-sm-12 text-center mt-3">
                  <p>
                    سال مالی میتواند خالی باشد ( در صورت عدم تعیین سال مالی ،
                    همه آنها برای شما لیست خواهند شد)
                  </p>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setMaliYearModal({
                  ...maliYearModal,
                  showModal: false,
                });
              }}
              variant={"danger"}
            >
              انصراف و بستن
            </Button>
          </Modal.Footer>
        </Portal>
        <Portal show={configModalShow}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {maliYearModal.maliYearName ? (
                <>
                  جستجو/ فیلتر گزارشات برای سال مالی{" "}
                  <span span className="text-success font-bold">
                    {maliYearModal.maliYearName}
                  </span>
                </>
              ) : (
                " جستجو/ فیلتر گزارشات"
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {saveConfigShow ? (
              <div className="row">
                <div className="col-sm-6 m-auto p-3 set-column-middle input-wrapper">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>نام فیلتر گزارش جدید</InputGroup.Text>
                    <FormControl
                      name="configName"
                      id="configName"
                      type="text"
                      value={configName.name}
                      className={configName.nameErr ? "errorBorder" : ""}
                      onChange={(e) => {
                        setConfigName({
                          ...configName,
                          name: e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                  <strong className="error text-center">
                    {configName.nameErr}
                  </strong>
                </div>
              </div>
            ) : (
              <div className="row">
                <Col sm={12} lg={12} className="input-wrapper multipleSelect">
                  <InputGroup className="mb-3 flex-nowrap">
                    <InputGroup.Text>
                      جستجو/ فیلتر گزارشات بر اساس
                    </InputGroup.Text>
                    <div className="multipleSelect-wrapper">
                      <ChevronDown
                        className="cpointer"
                        onClick={() => {
                          setMultipleSelectItems((prevState) => ({
                            ...prevState,
                            showList: !prevState.showList,
                          }));
                        }}
                      />
                      <ul className="unstyled-list set-row-middle flex-wrap">
                        {multipleSelectItems.selectedItems.length > 0 ? (
                          multipleSelectItems.selectedItems.map(
                            (item, index) => {
                              return (
                                <li key={index} id={item.id}>
                                  {item.value}
                                  <i
                                    className="close"
                                    onClick={() => {
                                      let newNonsList =
                                        multipleSelectItems.nonSelectedItems;
                                      let newSlcList = [];
                                      multipleSelectItems.selectedItems.forEach(
                                        (si) => {
                                          if (si.id === item.id) {
                                            newNonsList.push(item);
                                            newSlcList =
                                              multipleSelectItems.selectedItems.filter(
                                                function (value, index, arr) {
                                                  return value.id !== item.id;
                                                }
                                              );
                                          }
                                        }
                                      );
                                      setMultipleSelectItems((prevState) => ({
                                        ...prevState,
                                        selectedItems: newSlcList,
                                        nonSelectedItems: newNonsList,
                                      }));
                                    }}
                                  >
                                    &times;
                                  </i>
                                </li>
                              );
                            }
                          )
                        ) : (
                          <div
                            className="text-center"
                            style={{ fontSize: "0.8em" }}
                          >
                            مقداری انتخاب نشده است
                          </div>
                        )}
                      </ul>
                    </div>
                  </InputGroup>
                  {multipleSelectItems.showList && (
                    <div className="multipleSelect-items">
                      <ul className="unstyled-list set-row-middle flex-wrap">
                        {/* {multipleSelectItems.selectedItems.length > 0 ? ( */}

                        {multipleSelectItems.selectedItems.map(
                          (item, index) => {
                            return (
                              <li
                                key={index}
                                id={item.id}
                                className="bg-success text-white mx-1 py-1 px-2"
                              >
                                {item.value}
                              </li>
                            );
                          }
                        )}
                        {multipleSelectItems.nonSelectedItems.map(
                          (item, index) => {
                            return (
                              <li
                                key={index}
                                id={item.id}
                                className="mx-1 py-1 px-2"
                                onClick={() => {
                                  let newNonsList = [];
                                  let newSlcList =
                                    multipleSelectItems.selectedItems;
                                  multipleSelectItems.nonSelectedItems.forEach(
                                    (nsi) => {
                                      if (nsi.id === item.id) {
                                        newSlcList.push(item);
                                        newNonsList =
                                          multipleSelectItems.nonSelectedItems.filter(
                                            function (value, index, arr) {
                                              return value.id !== item.id;
                                            }
                                          );
                                      }
                                    }
                                  );
                                  setMultipleSelectItems((prevState) => ({
                                    ...prevState,
                                    selectedItems: newSlcList,
                                    nonSelectedItems: newNonsList,
                                  }));
                                }}
                              >
                                {item.value}
                              </li>
                            );
                          }
                        )}

                        {/* ) : (
                        <div
                          className="text-center"
                          style={{ fontSize: "0.8em" }}
                        >
                          همه مقادیر انتخاب شده اند
                        </div>
                        )} */}
                      </ul>
                    </div>
                  )}
                </Col>
                <Col sm={12} lg={12} className="multipleSelectInputs">
                  <div className="row">
                    {multipleSelectItems.selectedItems.map((item, index) => {
                      return (
                        <Col sm={3} className="input-wrapper" key={index}>
                          <InputGroup className="mb-3 flex-nowrap">
                            <InputGroup.Text> {item.value}</InputGroup.Text>
                            {item.type === "text" ? (
                              <FormControl
                                name={item.name}
                                id={item.name}
                                type="text"
                                value={inputs[item.name]}
                                onChange={onChangeHandler}
                              />
                            ) : item.type === "date" ? (
                              <DateInput
                                name={item.name}
                                onChange={(e) => {
                                  setInputs({
                                    ...inputs,
                                    [item.name]: e.target.value.split("T")[0],
                                  });
                                }}
                                autoOk={true}
                                value={inputs[item.name]}
                              />
                            ) : (
                              <Form.Select
                                value={inputs[item.name]}
                                name={item.name}
                                id={item.name}
                                onChange={onChangeHandler}
                              >
                                <option value="">انتخاب کنید</option>
                                {partNames.list.map((op, index) => {
                                  return (
                                    <option
                                      value={op.key}
                                      key={index}
                                      id={op.key}
                                    >
                                      {op.value}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            )}
                          </InputGroup>
                        </Col>
                      );
                    })}
                  </div>
                </Col>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {saveConfigShow ? (
              <>
                <Button onClick={saveConfig} variant={"success"}>
                  ذخیره
                </Button>
                <Button
                  onClick={() => {
                    setSaveConfigShow(false);
                  }}
                  variant={"danger"}
                >
                  بازگشت
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setSaveConfigShow(true);
                  }}
                  variant={"info"}
                  className="text-white"
                >
                  ذخیره گزارش
                </Button>
                <Button onClick={submitConfig} variant={"primary"}>
                  اعمال گزارش
                </Button>
                <Button onClick={resetConfigModal} variant={"danger"}>
                  بستن
                </Button>
              </>
            )}
          </Modal.Footer>
        </Portal>
      </div>
    </Dashboard>
  );
}

export default DocList;
