import React, { useState, useEffect } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import swal from "sweetalert";
import { setImageProfile, toggleLoading, setCounters } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import IRCheck from "ircheck";
import "./Setting.scss";
import {
  Trash,
  PatchCheckFill,
  XOctagonFill,
  PencilSquare,
  CheckLg,
  XLg,
} from "react-bootstrap-icons";
import { Container, Row, Col, Nav, Table, Form, InputGroup, FormControl } from "react-bootstrap";
import { IMAGEURLS } from "../../utils/Constants";
import EnumItem from "./EnumItem";
import EnumItemHasParent from "./EnumItemHasParent";
function Setting() {
  const reduxState = useSelector((state) => state);
  const [hyperlinkForAllDocs, setHyperlinkForAllDocs] = useState(true);
  const dispatch = useDispatch();
  const [pills, setPills] = useState("1");
  const [usersList, setUsersList] = useState({
    newUserUserName: "",
    newUSerPassword: "",
    newUserUserNameErr: "",
    newUSerPasswordErr: "",
    newUserIsAdmin: false,
    newUserNationalCode: "",
    newUserNationalCodeErr: "",
    // **************************
    editedUserUserName: "",
    editedUserUserNameErr: false,
    editedUserPassword: "",
    editedUserPasswordErr: false,
    editedUserIsAdmin: false,
    editMode: false,
    editedIdentity: "",
    // **************************
    list: [],
  });
  const [enums, setEnums] = useState([
    { enumType: "DocType", name: "انواع سند", items: [] },
    { enumType: "PartName", name: "مراکز هرینه", items: [] },
    { enumType: "ProjectName", name: "نام پروژه", items: [] },
  ]);
  const getData = (type) => {
    dispatch(toggleLoading(true));
    AxiosInterceptor.post("/Get", { type: type }).then((response) => {
      if (!response.data.includes("error")) {
        if (type === "All") {
          let temp = [...enums];
          response.data.forEach((item) => {
            if (item.key === "Users") {
              // here users is null
              // handle it by another call api
              // setUsersList({
              //   ...usersList,
              //   list: item.value,
              // });
            } else if (item.key === "Profile") {
              let v = item.value;
              if (v[0].value) {
                dispatch(setImageProfile(IMAGEURLS + v[0].value));
              }
            } else {
              enums.forEach((e, index) => {
                if (e.enumType === item.key) {
                  temp[index].items = item.value;
                }
              });
            }
          });
          setEnums(temp);
        } else if (type === "Users") {
          setUsersList({
            ...usersList,
            list: response.data,
          });
        }

      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
      }
      dispatch(toggleLoading(false));
    });
  };
  /* 
  ================================================
  ===================== USER =====================
  ================================================
  */
  const deleteUser = (ncode, uname) => {
    if (usersList.list.length === 1) {
      swal({
        icon: "error",
        title: "خطا",
        text: "وجود حداقل یک کاربر الزامی است",
        button: "متوجه شدم",
      });
    } else {
      swal({
        title: "مطمئن هستید؟",
        text: " آیا از حذف کاربر " + uname + " اطمینان دارید؟ ",
        icon: "warning",
        buttons: ["خیر", "بله"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let myList = usersList.list.filter(
            (usr) => usr.nationalCode !== ncode
          );
          AxiosInterceptor.post("/Save", {
            list: JSON.stringify(myList),
            type: "Users",
          }).then((response) => {
            if (!response.data.includes("error")) {
              setUsersList((prevState) => ({
                ...prevState,
                list: response.data,
              }));
              dispatch(setCounters({
                bargCount: reduxState.bargCount,
                userCount: reduxState.userCount - 1,
                docCount: reduxState.docCount
              }));
            } else {
              swal({
                icon: "error",
                title: "خطا",
                text: response.data.message,
                button: "متوجه شدم",
              });
            }
          });
        }
      });
    }
  };
  const saveNewUser = () => {
    let myList = [];
    let isValid = true;
    if (usersList.newUserNationalCode === "") {
      setUsersList({
        ...usersList,
        newUserNationalCodeErr: "کد ملی کاربر جدید نمیتواند خالی باشد",
      });
    } else if (usersList.newUserNationalCode.length !== 10
      || !IRCheck.National.isNationalCodeValid(usersList.newUserNationalCode)
    ) {
      setUsersList({
        ...usersList,
        newUserNationalCodeErr:
          "لطفا کد ملی معتبر وارد نمایید.",
      });
    } else if (usersList.newUserUserName === "") {
      setUsersList({
        ...usersList,
        newUserUserNameErr: "نام کاربری جدید نمیتواند خالی باشد",
      });
    } else if (usersList.newUSerPassword === "") {
      setUsersList({
        ...usersList,
        newUSerPasswordErr: "رمز عبور کاربر جدید نمیتواند خالی باشد",
      });
    } else if (usersList.newUSerPassword.length < 4) {
      setUsersList({
        ...usersList,
        newUSerPasswordErr:
          "رمز عبور کاربر جدید میبایست بیشتر از 4 کاراکتر باشد",
      });
    } else {
      for (let item in usersList.list) {
        if (
          usersList.newUserNationalCode === usersList.list[item].nationalCode
        ) {
          setUsersList({
            ...usersList,
            newUserNationalCodeErr: "این کد ملی قبلا ثبت شده است",
          });
          isValid = false;
        } else if (
          usersList.newUSerPassword === usersList.list[item].password
        ) {
          setUsersList({
            ...usersList,
            newUSerPasswordErr: "این رمزعبور قبلا استفاده شده است",
          });
          isValid = false;
        }
      }
      if (isValid) {
        let newU = [
          {
            username: usersList.newUserUserName,
            password: usersList.newUSerPassword,
            nationalCode: usersList.newUserNationalCode,
            isAdmin: usersList.newUserIsAdmin,
          },
        ];
        myList = [...usersList.list, ...newU];
        AxiosInterceptor.post("/Save", {
          list: JSON.stringify(myList),
          type: "Users",
        }).then((response) => {
          if (!response.data.includes("error")) {
            setUsersList((prevState) => ({
              ...prevState,
              newUserUserName: "",
              newUSerPassword: "",
              newUserUserNameErr: "",
              newUSerPasswordErr: "",
              newUserIsAdmin: false,
              newUserNationalCode: "",
              newUserNationalCodeErr: "",
              list: response.data,
            }));
            dispatch(setCounters({
              bargCount: reduxState.bargCount,
              userCount: reduxState.userCount + 1,
              docCount: reduxState.docCount
            }));
          } else {
            swal({
              icon: "error",
              title: "خطا",
              text: response.data.message,
              button: "متوجه شدم",
            });
          }
        });
      }
    }
  };
  const updateUser = () => {
    let myList = usersList.list;
    let isValid = true;
    if (usersList.editedUserUserName === "") {
      setUsersList((prevState) => ({
        ...prevState,
        editedUserUserNameErr: true,
      }));
      isValid = false;
    }
    if (usersList.editedUserPassword === "") {
      setUsersList((prevState) => ({
        ...prevState,
        editedUserPasswordErr: true,
      }));
      isValid = false;
    }
    if (isValid) {
      myList.forEach((el) => {
        if (el.nationalCode === usersList.editedIdentity) {
          el.isAdmin = usersList.editedUserIsAdmin;
          el.username = usersList.editedUserUserName;
          el.password = usersList.editedUserPassword;
        }
      });
      AxiosInterceptor.post("/Save", {
        list: JSON.stringify(myList),
        type: "Users",
      }).then((response) => {
        if (!response.data.includes("error")) {
          setUsersList((prevState) => ({
            ...prevState,
            list: response.data,
          }));
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
        }
        setUsersList((prevState) => ({
          ...prevState,
          editMode: false,
        }));
      });
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "پر کردن همه فیلد ها الزامی است",
        button: "متوجه شدم",
      });
    }
  };
  const profileChangeHandler = (e) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 5000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 5 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      dispatch(toggleLoading(true));
      let reader = new FileReader();
      reader.onloadend = () => {
        AxiosInterceptor
          .post("/Upload", { file: reader.result, name: fileName })
          .then((response) => {
            if (
              typeof response.data === "string" &&
              response.data.includes("error")
            ) {
              swal({
                icon: "error",
                title: "خطا",
                text: response.data,
                button: "متوجه شدم",
              });
            } else {
              let imageId = response.data;
              AxiosInterceptor
                .post("/Save", {
                  list: JSON.stringify([{ key: 1, value: imageId }]),
                  type: "Profile",
                })
                .then((response) => {
                  if (
                    typeof response.data === "string" &&
                    response.data.includes("error")
                  ) {
                    swal({
                      icon: "error",
                      title: "خطا",
                      text: response.data,
                      button: "متوجه شدم",
                    });
                  } else {
                    swal({
                      icon: "success",
                      title: "موفقیت",
                      text: "تصویر شما با موفقیت بارگذاری شد",
                      button: "متوجه شدم",
                    });

                    dispatch(setImageProfile(IMAGEURLS + imageId));
                  }
                  dispatch(toggleLoading(false));
                });
            }
          });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const deleteProfile = () => {
    swal({
      title: "مطمئن هستید؟",
      text: " آیا از حذف تصویر پروفایل اطمیان دارید؟ ",
      icon: "warning",
      buttons: ["خیر", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(toggleLoading(true));
        AxiosInterceptor
          .post("/Save", {
            list: JSON.stringify([{ key: 1, value: "" }]),
            type: "Profile",
          })
          .then((response) => {
            if (
              typeof response.data === "string" &&
              response.data.includes("error")
            ) {
              swal({
                icon: "error",
                title: "خطا",
                text: response.data,
                button: "متوجه شدم",
              });
            } else {
              swal({
                icon: "success",
                title: "موفقیت",
                text: "تصویر شما با موفقیت حذف گردید",
                button: "متوجه شدم",
              });
              dispatch(setImageProfile(""));
            }
            dispatch(toggleLoading(false));
          })
      }
    });
  };
  useEffect(() => {
    getData("All");
  }, []);
  useEffect(() => {
    let hyperlinkStatus = localStorage.getItem("hyperlinkForAllDocs");
    if (hyperlinkStatus && hyperlinkStatus === "false") {
      setHyperlinkForAllDocs(false);
    }
  }, [])

  return (
    <Dashboard>
      <div className="Setting-wrapper p-3">
        <Nav
          variant="pills"
          defaultActiveKey="1"
          onSelect={(eventKey) => setPills(eventKey)}
          className="set-row-middle"
        >
          <Nav.Item>
            <Nav.Link eventKey="1">پروفایل</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="2">تنظیمات پایه</Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => { getData("Users") }}>
            <Nav.Link eventKey="3" title="Item">
              مدیریت کاربران
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Container fluid>
        {pills === "2" ? (
          <Row className="p-2">
            {enums.map((e, index) => {
              return (
                e.parent ?
                  <EnumItemHasParent
                    key={index}
                    enumType={e.enumType}
                    name={e.name}
                    items={e.items}
                    parent={e.parent}
                  />
                  :
                  <EnumItem
                    key={index}
                    enumType={e.enumType}
                    name={e.name}
                    items={e.items}
                  />)
            })}
            <Col sm={6} className="set-column-middle">
              <div className="checbx_container">
                <h4 className="mb-4">هایپرلینک برای کلیه اسناد</h4>
                <input type="checkbox" id="cbtest" checked={hyperlinkForAllDocs} onChange={(e) => {
                  setHyperlinkForAllDocs(e.target.checked);
                  localStorage.setItem("hyperlinkForAllDocs", e.target.checked);
                }} />
                <label htmlFor="cbtest" className="check-box"></label>
              </div>
            </Col>
          </Row>
        ) : pills === "3" ? (
          <Row className="p-2">
            <Col sm={12}>
              <fieldset>
                <legend>کاربر جدید :</legend>
                <Row>
                  <Col sm={12} lg={9}>
                    <Row>
                      <Col sm={12} lg={6} className="mb-2 set-column-middle">
                        <div className="input-wrapper">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>کد ملی کاربر جدید</InputGroup.Text>
                            <FormControl
                              type="number"
                              pattern="[1-9]"
                              className={`text-center ${usersList.newUserNationalCodeErr
                                ? "errorBorder"
                                : ""
                                }`}
                              id="newUserNationalCode"
                              name="newUserNationalCode"
                              value={usersList.newUserNationalCode}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  setUsersList({
                                    ...usersList,
                                    newUserNationalCode: e.target.value,
                                  });
                                }
                              }}
                              autoComplete="new-password"
                            />
                          </InputGroup>
                        </div>
                        <p className="error mt-1">
                          {usersList.newUserNationalCodeErr}
                        </p>
                      </Col>
                      <Col sm={12} lg={6} className="mb-2 set-column-middle">
                        <div className="input-wrapper">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>نام کاربری جدید</InputGroup.Text>
                            <FormControl
                              type="text"
                              className={`text-center ${usersList.newUserUserNameErr
                                ? "errorBorder"
                                : ""
                                }`}
                              id="newUserUserName"
                              name="newUserUserName"
                              value={usersList.newUserUserName}
                              onChange={(e) => {
                                setUsersList({
                                  ...usersList,
                                  newUserUserName: e.target.value,
                                });
                              }}
                              autoComplete="new-password"
                            />
                          </InputGroup>
                        </div>
                        <p className="error mt-1">
                          {usersList.newUserUserNameErr}
                        </p>
                      </Col>
                      <Col sm={12} lg={6} className="mb-2 set-column-middle">
                        <div className="input-wrapper">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>
                              رمز عبور کاربر جدید
                            </InputGroup.Text>
                            <FormControl
                              id="newUSerPassword"
                              name="newUSerPassword"
                              type="password"
                              className={`text-center ${usersList.newUSerPasswordErr
                                ? "errorBorder"
                                : ""
                                }`}
                              value={usersList.newUSerPassword}
                              onChange={(e) => {
                                setUsersList({
                                  ...usersList,
                                  newUSerPassword: e.target.value,
                                });
                              }}
                              autoComplete="new-password"
                            />
                          </InputGroup>
                        </div>
                        <p className="error mt-1">
                          {usersList.newUSerPasswordErr}
                        </p>
                      </Col>
                      <Col sm={12} lg={6} className="mb-2 set-row-middle pb-4">
                        <InputGroup.Text>دسترسی ادمین ؟</InputGroup.Text>
                        <Form.Group
                          className="mt-2 me-3"
                          controlId="formBasicCheckbox"
                        >
                          <input type="checkbox" name="newUserIsAdmin" id="newUserIsAdmin"
                            checked={usersList.newUserIsAdmin}
                            onChange={(e) => {
                              setUsersList({
                                ...usersList,
                                newUserIsAdmin: e.target.checked,
                              });
                            }} />
                          <label htmlFor="newUserIsAdmin" className="check-box"></label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} lg={3} className="mb-2 set-row-middle">
                    <button className="btn btn-success" onClick={saveNewUser}>
                      افزودن کاربر جدید
                    </button>
                  </Col>
                </Row>
              </fieldset>
              <hr />
              <Row>
                <Table striped bordered hover>
                  <thead className="gradiantBg">
                    <tr className="text-center">
                      <th scope="col">#</th>
                      <th scope="col">نام کاربری</th>
                      <th scope="col">کد ملی</th>
                      <th scope="col">رمز عبور</th>
                      <th scope="col">دسترسی ادمین</th>
                      <th scope="col">عملیات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList.list.map((user, index) => {
                      return (
                        <tr key={index} className="text-center">
                          <th scope="col">{index + 1}</th>
                          <th scope="col">
                            {usersList.editMode &&
                              usersList.editedIdentity === user.nationalCode ? (
                              <input
                                type="text"
                                className={`text-center px-2 ${usersList.editedUserUserNameErr
                                  ? "errorBorder"
                                  : ""
                                  }`}
                                value={usersList.editedUserUserName}
                                onChange={(e) => {
                                  setUsersList((prevState) => ({
                                    ...prevState,
                                    editedUserUserName: e.target.value,
                                  }));
                                }}
                              />
                            ) : (
                              user.username
                            )}
                          </th>
                          <th scope="col">{user.nationalCode}</th>
                          <th scope="col">
                            {usersList.editMode &&
                              usersList.editedIdentity === user.nationalCode ? (
                              <input
                                type="text"
                                className={`text-center px-2 ${usersList.editedUserPasswordErr
                                  ? "errorBorder"
                                  : ""
                                  }`}
                                value={usersList.editedUserPassword}
                                onChange={(e) => {
                                  setUsersList((prevState) => ({
                                    ...prevState,
                                    editedUserPassword: e.target.value,
                                  }));
                                }}
                              />
                            ) : (
                              "****"
                            )}
                          </th>
                          <th scope="col">
                            {usersList.editMode &&
                              usersList.editedIdentity === user.nationalCode ? (
                              <input
                                type="checkbox"
                                checked={usersList.editedUserIsAdmin}
                                onChange={(e) => {
                                  setUsersList((prevState) => ({
                                    ...prevState,
                                    editedUserIsAdmin: e.target.checked,
                                  }));
                                }}
                              />
                            ) : user.isAdmin ? (
                              <PatchCheckFill className="text-primary" />
                            ) : (
                              <XOctagonFill className="text-danger" />
                            )}
                          </th>
                          <th scope="col">
                            {usersList.editMode &&
                              usersList.editedIdentity === user.nationalCode ? (
                              <>
                                <span
                                  className="mx-2 cpointer text-success"
                                  onClick={() => {
                                    updateUser();
                                  }}
                                >
                                  ثبت
                                  <CheckLg />
                                </span>
                                <span
                                  className="mx-2 cpointer text-danger"
                                  onClick={() => {
                                    setUsersList((prevState) => ({
                                      ...prevState,
                                      editMode: false,
                                    }));
                                  }}
                                >
                                  انصراف
                                  <XLg />
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="text-warning text-nowrap ms-2"
                                  onClick={() => {
                                    setUsersList((prevState) => ({
                                      ...prevState,
                                      editedUserUserName: user.username,
                                      editedUserPassword: user.password,
                                      editedUserUserNameErr: false,
                                      editedUserPasswordErr: false,
                                      editMode: true,
                                      editedIdentity: user.nationalCode,
                                      editedUserIsAdmin: user.isAdmin,
                                    }));
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  ویرایش
                                  <PencilSquare />
                                </span>
                                <span
                                  className="text-danger text-nowrap me-2"
                                  onClick={() => {
                                    deleteUser(
                                      user.nationalCode,
                                      user.username
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  حذف
                                  <Trash />
                                </span>
                              </>
                            )}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        ) : pills === "1" ? (
          <Row className="p-2">
            <Col sm={6} className="m-auto set-column-middle position-relative">
              {reduxState.profile ? (
                <>
                  <h3 className="text-center">تصویر پروفایل شما</h3>
                  <img
                    src={reduxState.profile}
                    alt="profile"
                    width="100%"
                    height="auto"
                  />
                  <button
                    className="btn btn-danger"
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "20px",
                    }}
                    onClick={deleteProfile}
                  >
                    حذف تصویر
                  </button>
                </>
              ) : (
                <>
                  <h4 className="text-center my-5">
                    شما هنوز تصویری انتخاب نکرده اید
                  </h4>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      document.querySelector("#profileSelector").click();
                    }}
                  >
                    انتخاب تصویر
                  </button>
                  <input
                    type="file"
                    name="profile"
                    id="profileSelector"
                    className="d-none"
                    onChange={profileChangeHandler}
                  />
                </>
              )}
            </Col>
          </Row>
        ) : (
          <h1>-</h1>
        )}
      </Container>
    </Dashboard>
  );
}
export default Setting;
