import React, { useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import { BASEURL, IMAGEURLS, SITEURL } from "../../utils/Constants";
import {
  ArrowsExpand,
  Disc,
  Download,
  FileEarmark,
  FileEarmarkExcel,
  FileEarmarkMedical,
  FileEarmarkWord,
  FileImage,
  Link45deg,
  Printer,
  Trash,
} from "react-bootstrap-icons";
import Dashboard from "../../components/Dashboard/Dashboard";
import Wizard from "../../components/Wizard/Wizard";
import swal from "sweetalert";
import "./NewDoc.scss";
import AxiosInterceptor from "../../utils/AxiosInterceptor";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import { DateInput } from "react-hichestan-datetimepicker";
import Portal from "../../components/Portal/Portal";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { setCounters, toggleLoading } from "../../redux/actions";
import { toEnglishNumber } from "../../utils/toEnglishNumber";

function NewDoc() {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [inputs, setInputs] = useState({
    uploadDate: "",
    folderNumber: "",
    docDate: "",
    docNumber: "",
    docMain: "",
    docSub: "",
    docTurn: "",
    maliYear: "",
    binder: "",
    cover: "",
    page: "",
    docType: "",
    sectionName: "",
    docTitle: "",
    docName: "",
    peymankarName: "",
    docClass: "",
    projectNameId: "",
    docPrice: "",
    scanDate: "",
    scanStability: "",
    scanPersonUsername: "",
    excelHyperLink: false,
    cdAttachment: false,
    excelAttachment: false,
    wordAttachment: false,
    otherAttachment: false,
    description: "",
  });
  const [inputsErr, setInputsErr] = useState({
    uploadDateErr: "",
    folderNumberErr: "",
    docDateErr: "",
    docNumberErr: "",
    docMainErr: "",
    docSubErr: "",
    docTurnErr: "",
    binderErr: "",
    coverErr: "",
    pageErr: "",
    docTypeErr: "",
    sectionNameErr: "",
    docTitleErr: "",
    docNameErr: "",
    docClassErr: "",
    projectNameErr: "",
    descriptionErr: "",
  });
  const [isADMIN, setIsADMIN] = useState(false);
  const [lastDocListLength, setLastDocListLength] = useState(0);
  const [leftDocImage, setLeftDocImage] = useState("");
  const [activeWizard, setActiveWizard] = useState(1);
  const [mode, setMode] = useState("SAVE");
  const [staticDatas, setStaticDatas] = useState({
    scanStabilities: [],
    partNames: [],
    docTypes: [],
    projectNames: [],
  });
  const [docList, setDocList] = useState([]);
  const [cdAttachmentList, setCdAttachmentList] = useState([]);
  const [excelAttachmentList, setExcelAttachmentList] = useState([]);
  const [wordAttachmentList, setWordAttachmentList] = useState([]);
  const [otherAttachmentList, setOtherAttachmentList] = useState([]);
  const [docListPointer, setDocListPointer] = useState(0);
  const [selectedDocId, setSelectedDocId] = useState("");
  const [docListModal, setDocListModal] = useState({
    show: false,
    list: [],
  });
  const getData = () => {
    AxiosInterceptor.post("/Get", { type: "All" }).then((response) => {
      if (!response.data.includes("error")) {
        let partNamesList = [],
          docTypesList = [],
          projectNamesList = [];
        response.data.forEach((item) => {
          switch (item.key) {
            case "PartName":
              partNamesList = item.value;
              break;
            case "DocType":
              docTypesList = item.value;
              break;
            case "ProjectName":
              projectNamesList = item.value;
              break;
            default:
              break;
          }
        });
        setStaticDatas((prev) => ({
          ...staticDatas,
          partNames: partNamesList,
          docTypes: docTypesList,
          projectNames: projectNamesList,
        }));
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      }
    });
    AxiosInterceptor.post("/Get", { type: "Users" }).then((response) => {
      if (!response.data.includes("error")) {
        setStaticDatas((prev) => ({
          ...prev,
          scanStabilities: response.data,
        }));
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data,
          button: "متوجه شدم",
        });
      }
    });
  };
  const PrintImage = (source) => {
    if (leftDocImage) {
      var pwa = window.open("about:blank", "_new");
      pwa.document.open();
      pwa.document.write(
        "<html><head><script>function step1(){\n" +
          "setTimeout('step2()', 10);}\n" +
          "function step2(){window.print();window.close()}\n" +
          "</script></head><body onload='step1()'>\n" +
          "<img src='" +
          source +
          "' width='100%' height='auto' max-height='100%'/></body></html>"
      );
      pwa.document.close();
    } else {
      swal({
        title: "توجه",
        text: "ابتدا میبایست یک تصویر از لیست اسناد پرونده انتخاب کنید",
        icon: "error",
        buttons: "متوجه شدم",
        dangerMode: true,
      });
    }
  };
  const textChangeHandler = (e) => {
    if (e.target.name === "scanStability") {
      setInputs({ ...inputs, [e.target.name]: +e.target.value });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
  };
  const numberChangeHandler = (e, maxLength) => {
    if (e.target.name === "docPrice") {
      if (e.target.value === "") {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
      } else if (!isNaN(parseInt(e.target.value))) {
        let num = e.target.value;
        while (num.includes(",")) {
          num = num.replace(",", "");
        }
        num = parseInt(num);
        let amount = new Intl.NumberFormat().format(num);
        setInputs({ ...inputs, [e.target.name]: amount });
      }
    } else if (e.target.name === "docMain") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        folderNumber: e.target.value,
        docTurn: e.target.value,
      });
    } else {
      if (e.target.value.length < parseInt(maxLength)) {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
      }
    }

    // let { value, min, max } = e.target
    // value = Math.max(Number(min), Math.min(Number(max), Number(value)));
  };
  const checkBoxChangeHandler = (e) => {
    switch (e.target.name) {
      case "cdAttachment":
        setInputs({ ...inputs, [e.target.name]: e.target.checked, cdPath: "" });
        break;
      case "excelAttachment":
        setInputs({
          ...inputs,
          [e.target.name]: e.target.checked,
          excelPath: "",
        });
        break;
      case "wordAttachment":
        setInputs({
          ...inputs,
          [e.target.name]: e.target.checked,
          wordPath: "",
        });
        break;
      case "otherAttachment":
        setInputs({
          ...inputs,
          [e.target.name]: e.target.checked,
          otherPath: "",
        });
        break;
      default:
        setInputs({
          ...inputs,
          [e.target.name]: e.target.checked,
        });
        break;
    }
  };
  const fileChangeHandler = (e) => {
    try {
      let size = (e.target.files[0].size / 1024).toFixed(2);
      let fileName = e.target.files[0].name;
      if (size > 20000) {
        swal({
          icon: "error",
          title: "خطا",
          text: "حداکثر حجم مجاز 20 مگابایت است",
          button: "متوجه شدم",
        });
      } else if (fileName.includes(".exe")) {
        swal({
          icon: "error",
          title: "خطا",
          text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
          button: "متوجه شدم",
        });
      } else {
        dispatch(toggleLoading(true));
        let reader = new FileReader();
        reader.onloadend = () => {
          AxiosInterceptor.post("/Upload", {
            file: reader.result,
            name: fileName,
          }).then((response) => {
            if (
              typeof response.data === "string" &&
              response.data.includes("error")
            ) {
              swal({
                icon: "error",
                title: "خطا",
                text: response.data,
                button: "متوجه شدم",
              });
            } else {
              let arr = [...docList];
              arr.push(response.data);
              setDocList(arr);
              document.querySelector(".activedDocList").click();
            }
            dispatch(toggleLoading(false));
          });
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    } catch {}
  };
  const attachmentFileChangeHandler = (e, attachmentType) => {
    let size = (e.target.files[0].size / 1024).toFixed(2);
    let fileName = e.target.files[0].name;
    if (size > 20000) {
      swal({
        icon: "error",
        title: "خطا",
        text: "حداکثر حجم مجاز 20 مگابایت است",
        button: "متوجه شدم",
      });
    } else if (fileName.includes(".exe")) {
      swal({
        icon: "error",
        title: "خطا",
        text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
        button: "متوجه شدم",
      });
    } else {
      let reader = new FileReader();
      reader.onloadend = () => {
        dispatch(toggleLoading(true));
        let reader = new FileReader();
        reader.onloadend = () => {
          AxiosInterceptor.post("/Upload", {
            file: reader.result,
            name: fileName,
          })
            .then((response) => {
              if (
                typeof response.data === "string" &&
                response.data.includes("error")
              ) {
                swal({
                  icon: "error",
                  title: "خطا",
                  text: response.data,
                  button: "متوجه شدم",
                });
              } else {
                // setInputs({ ...inputs, [e.target.name]: response.data });
                switch (attachmentType) {
                  case "cd":
                    let prevFiles = [...cdAttachmentList];
                    prevFiles.push(response.data);
                    setCdAttachmentList(prevFiles);
                    break;
                  case "excel":
                    let prevFiles2 = [...excelAttachmentList];
                    prevFiles2.push(response.data);
                    setExcelAttachmentList(prevFiles2);
                    break;
                  case "word":
                    let prevFiles3 = [...wordAttachmentList];
                    prevFiles3.push(response.data);
                    setWordAttachmentList(prevFiles3);
                    break;
                  case "other":
                    let prevFiles4 = [...otherAttachmentList];
                    prevFiles4.push(response.data);
                    setOtherAttachmentList(prevFiles4);
                    break;
                  default:
                    break;
                }
              }
              dispatch(toggleLoading(false));
            })
            .catch((err) => {
              console.log(err);
              swal({
                icon: "error",
                title: "خطا",
                text: "بارگذاری با خطا مواجه شد.\nلطفا دوباره تلاش نمایید.",
                button: "متوجه شدم",
              });
              dispatch(toggleLoading(false));
            });
        };
        reader.readAsDataURL(e.target.files[0]);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const multipleFileChangeHandler = (e) => {
    var arr = [];
    let size,
      fileName,
      hasErr = 0;
    for (let i = 0; i < e.target.files.length; i++) {
      size = (e.target.files[i].size / 1024).toFixed(2);
      fileName = e.target.files[i].name;
      if (size > 20000) {
        swal({
          icon: "error",
          title: "خطا",
          text: "حداکثر حجم مجاز 20 مگابایت است",
          button: "متوجه شدم",
        });
        hasErr++;
      } else if (fileName.includes(".exe")) {
        swal({
          icon: "error",
          title: "خطا",
          text: "فایل‌های اجرایی را نمیتوان آپلود کرد",
          button: "متوجه شدم",
        });
        hasErr++;
      }
    }
    if (hasErr === 0) {
      // =============== readyToUpload ======================
      if (e.target.files.length > 0) {
        dispatch(toggleLoading(true));
      }
      for (let i = 0; i < e.target.files.length; i++) {
        let reader = new FileReader();
        reader.onloadend = () => {
          AxiosInterceptor.post("/Upload", {
            file: reader.result,
            name: e.target.files[i].name,
          })
            .then((response) => {
              if (
                typeof response.data === "string" &&
                response.data.includes("error")
              ) {
                swal({
                  icon: "error",
                  title: "خطا",
                  text: response.data,
                  button: "متوجه شدم",
                });
              } else {
                arr.push(response.data);
              }
            })
            .then(() => {
              if (i + 1 === e.target.files.length) {
                // the last file is Uploaded
                const waitForIt = () => {
                  if (arr.length === e.target.files.length) {
                    let newArr = [...docList, ...arr];
                    setDocList(newArr);
                    document.querySelector(".activedDocList").click();
                    dispatch(toggleLoading(false));
                  } else {
                    setTimeout(() => {
                      waitForIt();
                    }, 1000);
                  }
                };
                waitForIt();
              }
            });
        };
        reader.readAsDataURL(e.target.files[i]);
      }
    }
  };
  const GetScanResult = () => {
    let scanningWrapper = document.querySelector("#scanning-wrapper").style;
    AxiosInterceptor.post(SITEURL + "/Scanner/GetScanResult").then(
      (response) => {
        //TODO
        if (response.data.status === 1) {
          setTimeout(() => {
            GetScanResult();
          }, 1500);
        } else if (response.data.status === 0) {
          let newDocList = [...docList, ...response.data.list];
          setDocList(newDocList);
          scanningWrapper.zIndex = "-999";
          scanningWrapper.display = "none";
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data.message,
            button: "متوجه شدم",
          });
          scanningWrapper.zIndex = "-999";
          scanningWrapper.display = "none";
        }
      }
    );
  };
  const scannerFileChangeHandler = () => {
    let scanningWrapper = document.querySelector("#scanning-wrapper").style;
    scanningWrapper.zIndex = "999";
    scanningWrapper.display = "flex";
    AxiosInterceptor.get(
      "http://localhost:5003/Scanner/Start/token/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMzk0MDgzMDEzNjQwODE3IiwibmFtZSI6Ik1vaGFtbWFkIHZhIEFyaWEgQXJkaW4iLCJpYXQiOjMxMjY4NDY1MTY1MTMxNjU0MH0.NH-jB-vYwZQsDVjFY9T8EO9_xIlI7EFJIbn50qUz9Hw"
    ).then((response) => {
      if (response.data.Status) {
        GetScanResult();
      } else {
        swal({
          icon: "error",
          title: "خطا",
          text: response.data.message,
          button: "متوجه شدم",
        });
        scanningWrapper.zIndex = "-999";
        scanningWrapper.display = "none";
      }
    });
  };
  const deleteAllDocList = () => {
    // with password admin
    // let alertInput =
    //     document.createElement("div");
    // ReactDOM.render(
    //     <Form.Control
    //         id="adminPassword"
    //         name="adminPassword"
    //         type="password"
    //     />,
    //     alertInput
    // );
    // let el = alertInput.firstChild;
    // swal(
    //     "برای حذف همه اسناد باید کلمه عبور ادمین را وارد نمایید.",
    //     {
    //         content: el,
    //         buttons: ["لغو", "حذف"],
    //     }
    // ).then((value) => {
    //     if (value) {
    //         if (
    //             document.querySelector(
    //                 "#adminPassword"
    //             ).value === "Nama@123"
    //         ) {
    //             setDocList([]);
    //             setLeftDocImage("");
    //         } else {
    //             swal({
    //                 title: "توجه",
    //                 text: "رمز عبور وارد شده اشتباه است",
    //                 icon: "error",
    //                 buttons: "متوجه شدم",
    //                 dangerMode: true,
    //             });
    //         }
    //     }
    // });
    swal({
      title: "توجه",
      text: " آیا از حذف همه فایل ها مطمئن هستید؟ ",
      icon: "warning",
      buttons: ["انصراف", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setDocList([]);
        setLeftDocImage("");
      }
    });
  };
  const deleteThisFromDocList = (item) => {
    // with password admin
    // swal({
    //     title: "توجه",
    //     text: " آیا از حذف فایل " + item + " مطمئن هستید؟ ",
    //     icon: "warning",
    //     buttons: ["انصراف", "بله"],
    //     dangerMode: true,
    // }).then((willDelete) => {
    //     if (willDelete) {
    //         let alertInput = document.createElement("div");
    //         ReactDOM.render(
    //             <Form.Control
    //                 id="adminPassword"
    //                 name="adminPassword"
    //                 type="password"
    //             />,
    //             alertInput
    //         );
    //         let el = alertInput.firstChild;
    //         swal("برای حذف این سند باید کلمه عبور ادمین را وارد نمایید.", {
    //             content: el,
    //             buttons: ["لغو", "حذف"],
    //         }).then((value) => {
    //             if (value) {
    //                 if (document.querySelector("#adminPassword").value === "Nama@123") {
    //                     let newList = [];
    //                     docList.forEach((element) => {
    //                         if (element !== item) {
    //                             newList.push(element);
    //                         }
    //                     });
    //                     setDocList(newList);
    //                     if (newList.length <= 0) {
    //                         setLeftDocImage("");
    //                     } else {
    //                         if (
    //                             newList[0].includes(".png") ||
    //                             newList[0].includes(".PNG") ||
    //                             newList[0].includes(".jpg") ||
    //                             newList[0].includes(".JPG") ||
    //                             newList[0].includes(".jpeg") ||
    //                             newList[0].includes(".JPEG")
    //                         ) {
    //                             setLeftDocImage(IMAGEURLS + newList[0]);
    //                         } else {
    //                             setLeftDocImage("");
    //                         }
    //                     }
    //                 } else {
    //                     swal({
    //                         title: "توجه",
    //                         text: "رمز عبور وارد شده اشتباه است",
    //                         icon: "error",
    //                         buttons: "متوجه شدم",
    //                         dangerMode: true,
    //                     });
    //                 }
    //             }
    //         });
    //     }
    // });
    swal({
      title: "توجه",
      text: " آیا از حذف فایل " + item + " مطمئن هستید؟ ",
      icon: "warning",
      buttons: ["انصراف", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let newList = [];
        docList.forEach((element) => {
          if (element !== item) {
            newList.push(element);
          }
        });
        setDocList(newList);
        if (newList.length <= 0) {
          setLeftDocImage("");
        } else {
          if (
            newList[0].includes(".png") ||
            newList[0].includes(".PNG") ||
            newList[0].includes(".jpg") ||
            newList[0].includes(".JPG") ||
            newList[0].includes(".jpeg") ||
            newList[0].includes(".JPEG")
          ) {
            setLeftDocImage(IMAGEURLS + newList[0]);
          } else {
            setLeftDocImage("");
          }
        }
      }
    });
  };
  const Validation = () => {
    let isValidate = true;
    if (inputs.docDate === "") {
      setInputsErr((prevErrs) => ({
        ...prevErrs,
        docDateErr: "مقدار فیلد تاریخ سند نمیتواند خالی باشد",
      }));
      isValidate = false;
    } else {
      setInputsErr((prevErrs) => ({
        ...prevErrs,
        docDateErr: "",
      }));
    }
    if (inputs.docMain === "") {
      setInputsErr((prevErrs) => ({
        ...prevErrs,
        docMainErr: "مفدار فیلد شماره سند اصلی نمیتواند خالی باشد",
      }));
      isValidate = false;
    } else {
      setInputsErr((prevErrs) => ({
        ...prevErrs,
        docMainErr: "",
      }));
    }
    return isValidate;
  };
  const saveDoc = () => {
    let isValidate = Validation();
    if (isValidate) {
      dispatch(toggleLoading(true));
      let docListSharpSeprator = "";
      for (let item = 0; item < docList.length; item++) {
        item + 1 === docList.length
          ? (docListSharpSeprator += docList[item])
          : (docListSharpSeprator += docList[item] + "#");
      }
      let cdListSharpSeprator = "";
      for (let item = 0; item < cdAttachmentList.length; item++) {
        item + 1 === cdAttachmentList.length
          ? (cdListSharpSeprator += cdAttachmentList[item])
          : (cdListSharpSeprator += cdAttachmentList[item] + "#");
      }
      let excelListSharpSeprator = "";
      for (let item = 0; item < excelAttachmentList.length; item++) {
        item + 1 === excelAttachmentList.length
          ? (excelListSharpSeprator += excelAttachmentList[item])
          : (excelListSharpSeprator += excelAttachmentList[item] + "#");
      }
      let wordListSharpSeprator = "";
      for (let item = 0; item < wordAttachmentList.length; item++) {
        item + 1 === wordAttachmentList.length
          ? (wordListSharpSeprator += wordAttachmentList[item])
          : (wordListSharpSeprator += wordAttachmentList[item] + "#");
      }
      let otherListSharpSeprator = "";
      for (let item = 0; item < otherAttachmentList.length; item++) {
        item + 1 === otherAttachmentList.length
          ? (otherListSharpSeprator += otherAttachmentList[item])
          : (otherListSharpSeprator += otherAttachmentList[item] + "#");
      }
      AxiosInterceptor.post("/Edit", {
        FolderNumber: inputs.folderNumber,
        DocDate: inputs.docDate,
        DocNumber: inputs.docNumber,
        DocPrice: inputs.docPrice.replace(",", ""),
        MaliYear: inputs.maliYear,
        MainNumber: inputs.docMain,
        SubNumber: inputs.docSub,
        AtfNumber: inputs.docTurn,
        ZonkanNumber: inputs.binder,
        JeldNumber: inputs.cover,
        BargNumber: docList.length, //inputs.page
        DocType: inputs.docType,
        PartName: inputs.sectionName,
        DocSubject: inputs.docTitle,
        DocName: inputs.docName,
        PeymankarName: inputs.peymankarName,
        DocClass: inputs.docClass,
        ProjectNameId: inputs.projectNameId,
        ScanPersonId: inputs.scanStability,
        scanPersonUsername: inputs.scanPersonUsername,
        ScanDatetime: inputs.scanDate,
        CDPath: cdListSharpSeprator,
        ExcelPath: excelListSharpSeprator,
        WordPath: wordListSharpSeprator,
        OtherPath: otherListSharpSeprator,
        DocDescription: inputs.description,
        DocList: docListSharpSeprator,
        ExcelHyperLink: inputs.excelHyperLink,
      }).then((response) => {
        if (
          typeof response.data === "string" &&
          response.data.includes("error")
        ) {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data,
            button: "متوجه شدم",
          });
        } else if (response.data) {
          let reusableData = {
            scanStability: inputs.scanStability,
            scanDate: inputs.scanDate,
            docDate: inputs.docDate,
            binder: inputs.binder,
            docMain: inputs.docMain,
            docTurn: inputs.docTurn,
          };
          localStorage.setItem("reusableData", JSON.stringify(reusableData));
          dispatch(
            setCounters({
              bargCount: reduxState.bargCount + docList.length,
              userCount: reduxState.userCount,
              docCount: reduxState.docCount + 1,
            })
          );
          swal({
            icon: "success",
            title: "موفقیت",
            text: "پرونده شما با موفقیت ذخیر گردید",
            button: "متوجه شدم",
          });
          setMode("EDIT");
          setSelectedDocId(response.data);
          navigate("/editDocument/" + response.data);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: "ذخیره پرونده با خطا مواجه شد",
            button: "متوجه شدم",
          });
        }
        dispatch(toggleLoading(false));
      });
    } else {
      setActiveWizard(1);
      swal({
        icon: "error",
        title: "خطا",
        text: "ورودی های شما دارای مقادیر نامعتبر میباشند.",
        button: "متوجه شدم",
      });
    }
  };
  const updateDoc = () => {
    let isValidate = Validation();
    if (isValidate) {
      dispatch(toggleLoading(true));
      let docListSharpSeprator = "";
      for (let item = 0; item < docList.length; item++) {
        item + 1 === docList.length
          ? (docListSharpSeprator += docList[item])
          : (docListSharpSeprator += docList[item] + "#");
      }
      let cdListSharpSeprator = "";
      for (let item = 0; item < cdAttachmentList.length; item++) {
        item + 1 === cdAttachmentList.length
          ? (cdListSharpSeprator += cdAttachmentList[item])
          : (cdListSharpSeprator += cdAttachmentList[item] + "#");
      }
      let excelListSharpSeprator = "";
      for (let item = 0; item < excelAttachmentList.length; item++) {
        item + 1 === excelAttachmentList.length
          ? (excelListSharpSeprator += excelAttachmentList[item])
          : (excelListSharpSeprator += excelAttachmentList[item] + "#");
      }
      let wordListSharpSeprator = "";
      for (let item = 0; item < wordAttachmentList.length; item++) {
        item + 1 === wordAttachmentList.length
          ? (wordListSharpSeprator += wordAttachmentList[item])
          : (wordListSharpSeprator += wordAttachmentList[item] + "#");
      }
      let otherListSharpSeprator = "";
      for (let item = 0; item < otherAttachmentList.length; item++) {
        item + 1 === otherAttachmentList.length
          ? (otherListSharpSeprator += otherAttachmentList[item])
          : (otherListSharpSeprator += otherAttachmentList[item] + "#");
      }
      AxiosInterceptor.post("/Edit", {
        NDocId: selectedDocId,
        FolderNumber: inputs.folderNumber,
        DocDate: inputs.docDate,
        DocNumber: inputs.docNumber,
        DocPrice: inputs.docPrice.replace(",", ""),
        MaliYear: inputs.maliYear,
        MainNumber: inputs.docMain,
        SubNumber: inputs.docSub,
        AtfNumber: inputs.docTurn,
        ZonkanNumber: inputs.binder,
        JeldNumber: inputs.cover,
        BargNumber: docList.length, //inputs.page
        DocType: inputs.docType,
        PartName: inputs.sectionName,
        DocSubject: inputs.docTitle,
        DocName: inputs.docName,
        PeymankarName: inputs.peymankarName,
        DocClass: inputs.docClass,
        ProjectNameId: inputs.projectNameId,
        ScanPersonId: inputs.scanStability,
        scanPersonUsername: inputs.scanPersonUsername,
        ScanDatetime: inputs.scanDate,
        CDPath: cdListSharpSeprator,
        ExcelPath: excelListSharpSeprator,
        WordPath: wordListSharpSeprator,
        OtherPath: otherListSharpSeprator,
        DocDescription: inputs.description,
        DocList: docListSharpSeprator,
        ExcelHyperLink: inputs.excelHyperLink,
      }).then((response) => {
        if (
          typeof response.data === "string" &&
          response.data.includes("error")
        ) {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data,
            button: "متوجه شدم",
          });
        } else if (response.data) {
          dispatch(
            setCounters({
              bargCount:
                reduxState.bargCount + (docList.length - lastDocListLength),
              userCount: reduxState.userCount,
              docCount: reduxState.docCount,
            })
          );
          let reusableData = {
            scanStability: inputs.scanStability,
            scanDate: inputs.scanDate,
            docDate: inputs.docDate,
            binder: inputs.binder,
            docMain: inputs.docMain,
            docTurn: inputs.docTurn,
          };
          localStorage.setItem("reusableData", JSON.stringify(reusableData));
          setSelectedDocId(response.data);
          swal({
            icon: "success",
            title: "موفقیت",
            text: "پرونده شما با موفقیت به روزرسانی گردید",
            button: "متوجه شدم",
          });
          setLastDocListLength(docList.length);
        } else {
          swal({
            icon: "error",
            title: "خطا",
            text: "به روزرسانی پرونده با خطا مواجه شد",
            button: "متوجه شدم",
          });
        }
        dispatch(toggleLoading(false));
      });
    } else {
      setActiveWizard(1);
      swal({
        icon: "error",
        title: "خطا",
        text: "ورودی های شما دارای مقادیر نامعتبر میباشند.",
        button: "متوجه شدم",
      });
    }
  };
  const deleteThisAttachment = (item, attachmentType) => {
    swal({
      title: "توجه",
      text: " آیا از حذف فایل " + item + " مطمئن هستید؟ ",
      icon: "warning",
      buttons: ["انصراف", "بله"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let newList = [];
        switch (attachmentType) {
          case "cd":
            cdAttachmentList.forEach((element) => {
              if (element !== item) {
                newList.push(element);
              }
            });
            setCdAttachmentList(newList);
            break;
          case "excel":
            excelAttachmentList.forEach((element) => {
              if (element !== item) {
                newList.push(element);
              }
            });
            setExcelAttachmentList(newList);
            break;
          case "word":
            wordAttachmentList.forEach((element) => {
              if (element !== item) {
                newList.push(element);
              }
            });
            setWordAttachmentList(newList);
            break;
          case "other":
            otherAttachmentList.forEach((element) => {
              if (element !== item) {
                newList.push(element);
              }
            });
            setOtherAttachmentList(newList);
            break;
          default:
            break;
        }
      }
    });
  };
  const searchDocForThisField = (fieldName) => {
    let payload = {
      page: "1",
      take: "100",
    };
    switch (fieldName) {
      case "docMain":
        if (inputs.docMain !== "") {
          payload.MainNumber = inputs.docMain;
        }
        break;
      case "docTurn":
        if (inputs.docTurn !== "") {
          payload.AtfNumber = inputs.docTurn;
        }
        break;
      case "binder":
        if (inputs.binder !== "") {
          payload.ZonkanNumber = inputs.binder;
        }
        break;
      case "docPrice":
        if (inputs.docPrice !== "") {
          payload.DocPrice = inputs.docPrice + ".0";
        }
        break;
      case "docTitle":
        if (inputs.docTitle !== "") {
          payload.DocSubject = inputs.docTitle;
        }
        break;
      case "description":
        if (inputs.description !== "") {
          payload.DocDescription = inputs.description;
        }
        break;
      case "peymankarName":
        if (inputs.peymankarName !== "") {
          payload.PeymankarName = inputs.peymankarName;
        }
        break;
      default:
        payload = {};
        break;
    }
    if (Object.keys(payload).length > 2) {
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/List", payload).then((response) => {
        if (
          typeof response.data === "string" &&
          response.data.includes("error")
        ) {
          swal({
            icon: "error",
            title: "خطا",
            text: response.data,
            button: "متوجه شدم",
          });
        } else {
          if (response.data.length === 0) {
            swal({
              icon: "error",
              title: "خطا",
              text: "نتیجه ای برای این مقادیر یافت نشد.",
              button: "متوجه شدم",
            });
          } else if (response.data.length === 1) {
            selectThisDocument(response.data[0]);
          } else {
            // >1
            setDocListModal({
              ...docListModal,
              show: true,
              list: response.data,
            });
          }
        }
        dispatch(toggleLoading(false));
      });
    } else {
      swal({
        icon: "error",
        title: "خطا",
        text: "مقادیر خالی را نمیتوان جست و جو نمود",
        button: "متوجه شدم",
      });
    }
  };
  const selectThisDocument = (doc) => {
    setDocListModal({ ...docListModal, show: false });
    navigate("/editDocument/" + doc.nDocId);
  };
  const checkForSavePrevDoc = () => {
    if (mode === "SAVE") {
      swal({
        icon: "warning",
        title: "توجه",
        text: "در حال حاضر یک سند در حال ساخت وجود دارد.\nسند قبلی خود را ذخیره میکنید؟",
        buttons: ["خیر", "ذخیره میکنم"],
      }).then((willDelete) => {
        if (willDelete) {
          saveDoc();
        } else {
          window.location.href = "/createNewDoc";
        }
      });
    } else {
      window.location.href = "/createNewDoc";
    }
  };
  useEffect(() => {
    getData();
    const url = location.pathname.split("/")[1];
    const docID = location.pathname.split("/")[2];
    if (url === "createNewDoc") {
      let myData = JSON.parse(localStorage.getItem("_data"));
      let reusableData = JSON.parse(localStorage.getItem("reusableData"));
      let hyperlinkStatus = localStorage.getItem("hyperlinkForAllDocs");
      let nowDate = toEnglishNumber(new Date().toLocaleDateString("fa-IR"));
      setIsADMIN(myData.user.isAdmin);
      if (myData.user.isAdmin) {
        if (reusableData) {
          setInputs((prevState) => ({
            ...prevState,
            uploadDate: nowDate,
            maliYear: reusableData.docDate
              ? toEnglishNumber(
                  new Date(reusableData.docDate)
                    .toLocaleDateString("fa-IR")
                    .split("/")[0]
                )
              : nowDate.split("/")[0],
            scanStability: reusableData.scanStability,
            scanDate: reusableData.scanDate,
            docDate: reusableData.docDate,
            binder: reusableData.binder,
            docMain: parseInt(reusableData.docMain) + 1,
            folderNumber: parseInt(reusableData.docMain) + 1,
            docTurn: parseInt(reusableData.docTurn) + 1,
            excelHyperLink:
              hyperlinkStatus && hyperlinkStatus === "false" ? false : true,
          }));
        } else {
          setInputs((prevState) => ({
            ...prevState,
            uploadDate: nowDate,
            excelHyperLink:
              hyperlinkStatus && hyperlinkStatus === "false" ? false : true,
          }));
        }
      } else {
        if (reusableData) {
          setInputs((prevState) => ({
            ...prevState,
            uploadDate: nowDate,
            maliYear: reusableData.docDate
              ? toEnglishNumber(
                  new Date(reusableData.docDate)
                    .toLocaleDateString("fa-IR")
                    .split("/")[0]
                )
              : nowDate.split("/")[0],
            scanDate: new Date().toLocaleDateString("en-US"),
            docDate: reusableData.docDate,
            binder: reusableData.binder,
            docMain: parseInt(reusableData.docMain) + 1,
            folderNumber: parseInt(reusableData.docMain) + 1,
            docTurn: parseInt(reusableData.docTurn) + 1,
            scanPersonUsername: myData.user.username,
            excelHyperLink:
              hyperlinkStatus && hyperlinkStatus === "false" ? false : true,
          }));
        } else {
          setInputs((prevState) => ({
            ...prevState,
            uploadDate: nowDate,
            scanPersonUsername: myData.user.username,
            scanDate: new Date().toLocaleDateString("en-US"),
            excelHyperLink:
              hyperlinkStatus && hyperlinkStatus === "false" ? false : true,
          }));
        }
      }
    } else {
      if (url === "editForHyperlink") {
        setActiveWizard(2);
      }
      if (!docID) {
        navigate("/documentsList");
      }
      setMode("EDIT");
      setSelectedDocId(docID);
      dispatch(toggleLoading(true));
      AxiosInterceptor.post("/List", { NDocId: docID }).then((response) => {
        if (
          typeof response.data === "string" &&
          response.data.includes("error")
        ) {
          navigate("/documentsList");
          swal({
            icon: "error",
            title: "خطا",
            text: response.data,
            button: "متوجه شدم",
          });
        } else if (response.data.length === 0) {
          navigate("/documentsList");
          swal({
            icon: "error",
            title: "خطا",
            text: "نتیجه ای برای این سند یافت نشد",
            button: "متوجه شدم",
          });
        } else {
          let result = response.data[0];
          setDocList(result.docListArray);
          setLastDocListLength(result.docListArray.length);
          setExcelAttachmentList(result.excelPathArray);
          setWordAttachmentList(result.wordPathArray);
          setOtherAttachmentList(result.otherPathArray);
          setCdAttachmentList(result.cdPathArray);
          let shouldSkip = false;
          result.docListArray.forEach((el) => {
            if (shouldSkip) {
              return;
            }
            if (
              el.includes(".JPG") ||
              el.includes(".jpg") ||
              el.includes(".png") ||
              el.includes(".PNG") ||
              el.includes(".jpeg") ||
              el.includes(".JPEG")
            ) {
              shouldSkip = true;
              setLeftDocImage(IMAGEURLS + el);
              return;
            }
          });
          setInputs((prevState) => ({
            ...prevState,
            uploadDate: result.insertDateTimeString,
            folderNumber: result.folderNumber ? result.folderNumber : "",
            docDate: result.docDate ? result.docDate : "",
            docNumber: result.docNumber ? result.docNumber : "",
            docPrice: result.docPrice
              ? new Intl.NumberFormat().format(parseInt(result.docPrice))
              : "",
            maliYear: result.maliYear ? result.maliYear : "",
            docMain: result.mainNumber ? result.mainNumber : "",
            docSub: result.subNumber ? result.subNumber : "",
            docTurn: result.atfNumber ? result.atfNumber : "",
            binder: result.zonkanNumber ? result.zonkanNumber : "",
            cover: result.jeldNumber ? result.jeldNumber : "",
            page: result.bargNumber ? result.bargNumber : "",
            docType: result.docType ? result.docType : "",
            sectionName: result.partName ? result.partName : "",
            docTitle: result.docSubject ? result.docSubject : "",
            docName: result.docName ? result.docName : "",
            peymankarName: result.peymankarName ? result.peymankarName : "",
            docClass: result.docClass ? result.docClass : "",
            projectNameId: result.projectNameId ? result.projectNameId : "",
            scanDate: result.scanDatetime ? result.scanDatetime : "",
            scanStability: result.scanPersonId ? result.scanPersonId : "",
            excelHyperLink: result.excelHyperLink,
            excelAttachment: result.excelPathArray.length > 0 ? true : false,
            wordAttachment: result.wordPathArray.length > 0 ? true : false,
            otherAttachment: result.otherPathArray.length > 0 ? true : false,
            cdAttachment: result.cdPathArray.length > 0 ? true : false,
            description: result.docDescription ? result.docDescription : "",
          }));
        }
        dispatch(toggleLoading(false));
      });
    }
  }, [location]);
  return (
    <Dashboard imSaveNewDoc={true} checkForSavePrevDoc={checkForSavePrevDoc}>
      <div className="NewDoc-wrapper p-3">
        <div id="scanning-wrapper" className="set-column-middle">
          <div className="loading"></div>
          <h4 className="text-white mt-4">در حال اسکن اطلاعات</h4>
          <p className="text-white">لطفا صبر کنید ...</p>
        </div>
        <Wizard active={activeWizard} setActiveWizard={setActiveWizard}>
          <div>
            <Row>
              <Col sm={12} lg={5} style={{ height: "60vh" }}>
                <Row style={{ maxHeight: "100%", overflowY: "auto" }}>
                  {activeWizard === 1 ? (
                    <>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>تاریخ بارگذاری</InputGroup.Text>
                          <FormControl
                            disabled
                            type="text"
                            style={{ cursor: "not-allowed" }}
                            value={inputs.uploadDate}
                          />
                          <span className="tooltiptext">
                            در این محل تاریخ اسکن بر اساس تاریخ روز درج میگردد و
                            کاربر نمیتواند آن را تغییر دهد .
                          </span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>شماره فولدر</InputGroup.Text>
                          <FormControl
                            name="folderNumber"
                            id="folderNumber"
                            type="text"
                            value={inputs.folderNumber}
                            onChange={textChangeHandler}
                          />
                          <span className="tooltiptext">
                            شماره فولدری که سند در دیتابیس به خود اختصاص داده.
                          </span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3 flex-nowrap">
                          <InputGroup.Text>تاریخ سند</InputGroup.Text>
                          <DateInput
                            name={"docDate"}
                            onChange={(e) => {
                              let d = e.target.value.split("T")[0];
                              setInputs({
                                ...inputs,
                                docDate: d,
                                maliYear: toEnglishNumber(
                                  new Date(d)
                                    .toLocaleDateString("fa-IR")
                                    .split("/")[0]
                                ),
                              });
                            }}
                            className={
                              inputsErr.docDateErr ? "errorBorder" : ""
                            }
                            autoOk={true}
                            value={inputs.docDate}
                          />
                        </InputGroup>
                        <p className="error text-nowrap text-center">
                          {inputsErr.docDateErr}
                        </p>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("docMain");
                            }}
                          >
                            اصلی
                          </InputGroup.Text>
                          <FormControl
                            name="docMain"
                            id="docMain"
                            type="number"
                            className={
                              inputsErr.docMainErr ? "errorBorder" : ""
                            }
                            value={inputs.docMain}
                            onChange={(e) => {
                              numberChangeHandler(e, 7);
                            }}
                          />
                          <span className="tooltiptext">فقط عدد تا 6 رقم</span>
                        </InputGroup>
                        <p className="error text-nowrap text-center">
                          {inputsErr.docMainErr}
                        </p>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>فرعی</InputGroup.Text>
                          <FormControl
                            name="docSub"
                            id="docSub"
                            type="number"
                            disabled
                            style={{ cursor: "not-allowed" }}
                            value={inputs.docSub}
                            // onChange={(e) => {
                            //   numberChangeHandler(e, 7);
                            // }}
                          />
                          {/* <span className="tooltiptext">فقط عدد تا 6 رقم</span> */}
                          <span className="tooltiptext">
                            این فیلد موقتا غیر فعال است
                          </span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("docTurn");
                            }}
                          >
                            عطف
                          </InputGroup.Text>
                          <FormControl
                            name="docTurn"
                            type="number"
                            value={inputs.docTurn}
                            onChange={(e) => {
                              numberChangeHandler(e, 7);
                            }}
                          />
                          <span className="tooltiptext">فقط عدد تا 6 رقم</span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>سال مالی</InputGroup.Text>
                          <FormControl
                            name="maliYear"
                            id="maliYear"
                            type="number"
                            value={inputs.maliYear}
                            onChange={(e) => {
                              numberChangeHandler(e, 5);
                            }}
                          />
                          <span className="tooltiptext">فقط عدد تا 4 رقم</span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("binder");
                            }}
                          >
                            زونکن
                          </InputGroup.Text>
                          <FormControl
                            name="binder"
                            id="binder"
                            type="number"
                            value={inputs.binder}
                            onChange={(e) => {
                              numberChangeHandler(e, 7);
                            }}
                          />
                          <span className="tooltiptext">
                            عدد روی زونکن تا 6 رقم
                          </span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>جلد</InputGroup.Text>
                          <FormControl
                            name="cover"
                            id="cover"
                            type="number"
                            value={inputs.cover}
                            disabled
                            style={{ cursor: "not-allowed" }}
                            // onChange={(e) => {
                            //   numberChangeHandler(e, 5);
                            // }}
                          />
                          {/* <span className="tooltiptext">فقط عدد تا 4 رقم</span> */}
                          <span className="tooltiptext">
                            این فیلد موقتا غیر فعال است
                          </span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>برگ</InputGroup.Text>
                          <FormControl
                            name="page"
                            id="page"
                            type="number"
                            // value={inputs.page}
                            value={docList.length}
                            // onChange={(e) => {
                            //   numberChangeHandler(e, 5);
                            // }}
                            disabled
                          />
                          {/* <span className="tooltiptext">فقط عدد تا 4 رقم</span> */}
                          <span className="tooltiptext">
                            تعداد آیتم های آپلود شده که به صورت اتوماتیک توسط
                            سیستم محاسبه میگردد
                          </span>
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper flex-nowrap">
                        <Form.Group className="set-row-middle mb-3">
                          <InputGroup.Text>نوع سند</InputGroup.Text>
                          <Form.Select
                            value={inputs.docType}
                            name="docType"
                            id="docType"
                            // disabled
                            // style={{ cursor: "not-allowed" }}
                            onChange={textChangeHandler}
                          >
                            <option value="">انتخاب کنید</option>
                            {staticDatas.docTypes.map((op, index) => {
                              return (
                                <option value={op.key} key={index} id={op.key}>
                                  {op.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <span className="tooltiptext">
                            انتخاب به صورت کمبو باکس
                          </span>
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper flex-nowrap">
                        <Form.Group className="set-row-middle mb-3">
                          <InputGroup.Text>مرکز هزینه</InputGroup.Text>
                          <Form.Select
                            value={inputs.sectionName}
                            name="sectionName"
                            id="sectionName"
                            onChange={textChangeHandler}
                            // disabled
                            // style={{ cursor: "not-allowed" }}
                          >
                            <option value="">انتخاب کنید</option>
                            {staticDatas.partNames.map((op, index) => {
                              return (
                                <option value={op.key} key={index} id={op.key}>
                                  {op.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <span className="tooltiptext">
                            انتخاب به صورت کمبو باکس
                          </span>
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("docPrice");
                            }}
                          >
                            مبلغ سند(ریال)
                          </InputGroup.Text>
                          <FormControl
                            name="docPrice"
                            id="docPrice"
                            type="text"
                            value={inputs.docPrice}
                            onChange={(e) => {
                              numberChangeHandler(e, 1);
                            }}
                          />
                          <span className="tooltiptext">
                            فقط عدد و حتما مبلغ به ریال وارد شود
                          </span>
                        </InputGroup>
                      </Col>
                      {/* <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>نام سند</InputGroup.Text>
                          <FormControl
                            name="docName"
                            id="docName"
                            type="text"
                            value={inputs.docName}
                            disabled
                            style={{ cursor: "not-allowed" }}
                          // onChange={textChangeHandler}
                          />
                          <span className="tooltiptext">
                            این فیلد موقتا غیر فعال است
                          </span>
                        </InputGroup>
                      </Col> */}

                      <Col sm={12} lg={6} className="input-wrapper flex-nowrap">
                        <Form.Group className="set-row-middle mb-3">
                          <InputGroup.Text>نام پروژه</InputGroup.Text>
                          <Form.Select
                            value={inputs.projectNameId}
                            name="projectNameId"
                            id="projectNameId"
                            // disabled
                            // style={{ cursor: "not-allowed" }}
                            onChange={textChangeHandler}
                          >
                            <option value="">انتخاب کنید</option>
                            {staticDatas.projectNames.map((op, index) => {
                              return (
                                <option value={op.key} key={index} id={op.key}>
                                  {op.value}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <span className="tooltiptext">
                            انتخاب به صورت کمبو باکس
                          </span>
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("peymankarName");
                            }}
                          >
                            نام پیمانکار
                          </InputGroup.Text>
                          <FormControl
                            name="peymankarName"
                            id="peymankarName"
                            type="text"
                            value={inputs.peymankarName}
                            onChange={textChangeHandler}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={12} lg={6} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>کلاسه پرونده</InputGroup.Text>
                          <FormControl
                            name="docClass"
                            id="docClass"
                            type="text"
                            value={inputs.docClass}
                            // onChange={textChangeHandler}
                            disabled
                            style={{ cursor: "not-allowed" }}
                          />
                          {/* <span className="tooltiptext">
                        عدد و حرف با پذیرش خط فاصله و بدون / یا \
                      </span> */}
                          <span className="tooltiptext">
                            این فیلد موقتا غیر فعال است
                          </span>
                        </InputGroup>
                      </Col>

                      <Col sm={12} lg={12} className="input-wrapper">
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("docTitle");
                            }}
                          >
                            موضوع سند
                          </InputGroup.Text>
                          <FormControl
                            name="docTitle"
                            id="docTitle"
                            type="text"
                            value={inputs.docTitle}
                            onChange={textChangeHandler}
                            // disabled
                            // style={{ cursor: "not-allowed" }}
                          />
                          <span className="tooltiptext">
                            موضوع سند،با قابلیت پذیرش عدد و حرف تا 1000 کاراکتر
                          </span>
                          {/* <span className="tooltiptext">
                            این فیلد موقتا غیر فعال است
                          </span> */}
                        </InputGroup>
                      </Col>
                      {isADMIN ? (
                        <>
                          <Col sm={12} lg={6} className="input-wrapper">
                            <InputGroup className="mb-3 flex-nowrap">
                              <InputGroup.Text>تاریخ اسکن</InputGroup.Text>
                              <DateInput
                                name="scanDate"
                                onChange={(e) => {
                                  setInputs({
                                    ...inputs,
                                    scanDate: e.target.value.split("T")[0],
                                  });
                                }}
                                autoOk={true}
                                value={inputs.scanDate}
                              />
                            </InputGroup>
                          </Col>
                          <Col
                            sm={12}
                            lg={6}
                            className="input-wrapper flex-nowrap"
                          >
                            <Form.Group className="set-row-middle mb-3">
                              <InputGroup.Text>کاربر اسکن</InputGroup.Text>
                              <Form.Select
                                value={inputs.scanStability}
                                name="scanStability"
                                id="scanStability"
                                onChange={textChangeHandler}
                              >
                                <option value="">انتخاب کنید</option>
                                {staticDatas.scanStabilities.map(
                                  (op, index) => {
                                    return (
                                      <option
                                        value={op.id}
                                        key={index}
                                        id={op.id}
                                      >
                                        {op.username}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Select>
                              <span className="tooltiptext">
                                انتخاب به صورت کمبو باکس
                              </span>
                            </Form.Group>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col sm={12} lg={6} className="input-wrapper">
                            <InputGroup className="mb-3 flex-nowrap">
                              <InputGroup.Text>تاریخ اسکن</InputGroup.Text>
                              <FormControl
                                name="scanDate"
                                id="scanDate"
                                type="text"
                                value={new Date(
                                  inputs.scanDate
                                ).toLocaleDateString("fa-IR")}
                                disabled
                                style={{ cursor: "not-allowed" }}
                              />
                            </InputGroup>
                          </Col>
                          <Col
                            sm={12}
                            lg={6}
                            className="input-wrapper flex-nowrap"
                          >
                            <Form.Group className="set-row-middle mb-3">
                              <InputGroup.Text>کاربر اسکن</InputGroup.Text>
                              <FormControl
                                name="scanPersonUsername"
                                id="scanPersonUsername"
                                type="text"
                                value={inputs.scanPersonUsername}
                                disabled
                                style={{ cursor: "not-allowed" }}
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )}
                      <Col sm={12} lg={12} className="input-wrapper">
                        <InputGroup>
                          <InputGroup.Text
                            style={{
                              border: "2px solid #6e7ff3",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              searchDocForThisField("description");
                            }}
                          >
                            توضیحات
                          </InputGroup.Text>
                          <FormControl
                            as="textarea"
                            aria-label="With textarea"
                            name="description"
                            id="description"
                            type="text"
                            value={inputs.description}
                            onChange={textChangeHandler}
                          />
                        </InputGroup>
                      </Col>
                    </>
                  ) : activeWizard === 2 ? (
                    <>
                      <Col sm={12} lg={12} className="docsAttachment">
                        <Row>
                          <Col sm={12} className="options p-2">
                            <div className="set-row-middle justify-content-around">
                              <button
                                className="btn gradiantBg"
                                onClick={() => {
                                  document
                                    .getElementById("uploadSingleDoc")
                                    .click();
                                }}
                              >
                                <input
                                  id="uploadSingleDoc"
                                  type="file"
                                  className="d-none"
                                  onChange={fileChangeHandler}
                                />
                                بارگذاری سند تک برگ
                              </button>
                              <button
                                className="btn gradiantBg"
                                onClick={() => {
                                  document
                                    .getElementById("uploadMultiDocs")
                                    .click();
                                }}
                              >
                                <input
                                  type="file"
                                  id="uploadMultiDocs"
                                  className="d-none"
                                  name="files"
                                  webkitdirectory="true"
                                  directory="true"
                                  multiple
                                  onChange={multipleFileChangeHandler}
                                />
                                بارگذاری اسناد پوشه
                              </button>
                              <button
                                className="btn gradiantBg"
                                onClick={scannerFileChangeHandler}
                              >
                                بارگذاری از طریق اسکن
                              </button>
                            </div>
                          </Col>
                          <hr />
                          <Col
                            sm={12}
                            className="p-2"
                            style={{ height: "70%" }}
                          >
                            <div className="doclist-wrapper">
                              {docList.length === 0 && (
                                <div className="controls set-row-middle p-5">
                                  <h5 className="m-0">
                                    لیست اسناد خالی میباشد
                                  </h5>
                                </div>
                              )}
                              {docList.length > 0 && (
                                <>
                                  <ul className="unstyled-list text-center">
                                    {docList.map((doc, index) => {
                                      return (
                                        <li key={index}>
                                          <span
                                            className={
                                              index === docListPointer
                                                ? "activedDocList"
                                                : ""
                                            }
                                            id={"doclistItem" + index}
                                            onClick={(e) => {
                                              setDocListPointer(index);
                                              if (
                                                doc.includes(".jpg") ||
                                                doc.includes(".JPG") ||
                                                doc.includes(".jpeg") ||
                                                doc.includes(".JPEG") ||
                                                doc.includes(".png") ||
                                                doc.includes(".PNG")
                                              ) {
                                                setLeftDocImage(
                                                  IMAGEURLS + doc
                                                );
                                              }
                                            }}
                                          >
                                            {doc.includes(".jpg") ||
                                            doc.includes(".JPG") ||
                                            doc.includes(".jpeg") ||
                                            doc.includes(".JPEG") ||
                                            doc.includes(".png") ||
                                            doc.includes(".PNG") ? (
                                              <FileImage
                                                size="1.4rem"
                                                className="ms-1"
                                              />
                                            ) : (
                                              <FileEarmarkMedical
                                                size="1.4rem"
                                                className="ms-1"
                                              />
                                            )}
                                            {doc}
                                          </span>
                                          <Trash
                                            size="1.1rem"
                                            className="float-start text-danger cpointer"
                                            onClick={() => {
                                              deleteThisFromDocList(doc);
                                            }}
                                          />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div>
                                    <button
                                      className="btn gradiantBg"
                                      onClick={deleteAllDocList}
                                    >
                                      حذف همه
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col sm={12} lg={6} className="p-2">
                        <div
                          className={`attachments-wrapper pt-2 px-3 pb-0 ${
                            inputs.cdAttachment ? "active" : ""
                          }`}
                        >
                          <Row className="set-row-middle">
                            <h5 className="col-sm-6 title set-row-middle m-0">
                              <Disc
                                size="1.6rem"
                                color="#00ADD7"
                                className="ms-2"
                              />
                              CD
                            </h5>
                            <div className="col-sm-6 switch-buttons set-row-middle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="cdAttachment"
                                  checked={inputs.cdAttachment}
                                  id="cdAttachment"
                                  onChange={checkBoxChangeHandler}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={12} className="uploader">
                              <button
                                className="btn w-100"
                                disabled={!inputs.cdAttachment}
                                onClick={() => {
                                  document.querySelector("#cdUpload").click();
                                }}
                              >
                                بارگذاری
                                <input
                                  className="d-none"
                                  id="cdUpload"
                                  type="file"
                                  name="cdPath"
                                  onChange={(e) => {
                                    attachmentFileChangeHandler(e, "cd");
                                  }}
                                />
                              </button>
                            </Col>
                          </Row>
                          <Row className="attachments-list-wrapper">
                            <ul className="unstyled-list attachments-list set-column-middle p-0 m-0">
                              {cdAttachmentList.length > 0 ? (
                                cdAttachmentList.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item.substring(0, 16) + "..."}
                                      <Trash
                                        size="1.1rem"
                                        className="float-start text-danger cpointer mx-1"
                                        onClick={() => {
                                          deleteThisAttachment(item, "cd");
                                        }}
                                      />
                                      <a
                                        href={BASEURL + "/download?id=" + item}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Download
                                          size="1.1rem"
                                          className="float-start text-info cpointer me-4 ms-1"
                                        />
                                      </a>
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="text-center">
                                  فایلی به این بخش ضمیمه نشده است
                                </li>
                              )}
                            </ul>
                          </Row>
                        </div>
                      </Col>
                      <Col sm={12} lg={6} className="p-2">
                        <div
                          className={`attachments-wrapper pt-2 px-3 pb-0 ${
                            inputs.excelAttachment ? "active" : ""
                          }`}
                        >
                          <Row className="set-row-middle">
                            <h5 className="col-sm-6 title set-row-middle m-0">
                              <FileEarmarkExcel
                                size="1.6rem"
                                color="#1d6f42"
                                className="ms-2"
                              />
                              Excel
                            </h5>
                            <div className="col-sm-6 switch-buttons set-row-middle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="excelAttachment"
                                  checked={inputs.excelAttachment}
                                  id="excelAttachment"
                                  onChange={checkBoxChangeHandler}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={12} className="uploader">
                              <button
                                className="btn w-100"
                                disabled={!inputs.excelAttachment}
                                onClick={() => {
                                  document
                                    .querySelector("#excelUpload")
                                    .click();
                                }}
                              >
                                بارگذاری
                                <input
                                  className="d-none"
                                  id="excelUpload"
                                  type="file"
                                  name="excelPath"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  onChange={(e) => {
                                    attachmentFileChangeHandler(e, "excel");
                                  }}
                                />
                              </button>
                            </Col>
                          </Row>
                          <Row className="attachments-list-wrapper">
                            <ul className="unstyled-list attachments-list set-column-middle p-0 m-0">
                              {excelAttachmentList.length > 0 ? (
                                excelAttachmentList.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item.substring(0, 16) + "..."}
                                      <Trash
                                        size="1.1rem"
                                        className="float-start text-danger cpointer mx-1"
                                        onClick={() => {
                                          deleteThisAttachment(item, "excel");
                                        }}
                                      />
                                      <a
                                        href={BASEURL + "/download?id=" + item}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Download
                                          size="1.1rem"
                                          className="float-start text-info cpointer me-4 ms-1"
                                        />
                                      </a>
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="text-center">
                                  فایلی به این بخش ضمیمه نشده است
                                </li>
                              )}
                            </ul>
                          </Row>
                        </div>
                      </Col>
                      <Col sm={12} lg={6} className="p-2">
                        <div
                          className={`attachments-wrapper pt-2 px-3 pb-0 ${
                            inputs.wordAttachment ? "active" : ""
                          }`}
                        >
                          <Row className="set-row-middle">
                            <h5 className="col-sm-6 title set-row-middle m-0">
                              <FileEarmarkWord
                                size="1.6rem"
                                color="#2A5696"
                                className="ms-2"
                              />
                              WORD
                            </h5>
                            <div className="col-sm-6 switch-buttons set-row-middle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="wordAttachment"
                                  checked={inputs.wordAttachment}
                                  id="wordAttachment"
                                  onChange={checkBoxChangeHandler}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={12} className="uploader">
                              <button
                                className="btn w-100"
                                disabled={!inputs.wordAttachment}
                                onClick={() => {
                                  document.querySelector("#wordUpload").click();
                                }}
                              >
                                بارگذاری
                                <input
                                  className="d-none"
                                  id="wordUpload"
                                  type="file"
                                  name="wordPath"
                                  accept=".doc, .docx"
                                  onChange={(e) => {
                                    attachmentFileChangeHandler(e, "word");
                                  }}
                                />
                              </button>
                            </Col>
                          </Row>
                          <Row className="attachments-list-wrapper">
                            <ul className="unstyled-list attachments-list set-column-middle p-0 m-0">
                              {wordAttachmentList.length > 0 ? (
                                wordAttachmentList.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item.substring(0, 16) + "..."}
                                      <Trash
                                        size="1.1rem"
                                        className="float-start text-danger cpointer mx-1"
                                        onClick={() => {
                                          deleteThisAttachment(item, "word");
                                        }}
                                      />
                                      <a
                                        href={BASEURL + "/download?id=" + item}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Download
                                          size="1.1rem"
                                          className="float-start text-info cpointer me-4 ms-1"
                                        />
                                      </a>
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="text-center">
                                  فایلی به این بخش ضمیمه نشده است
                                </li>
                              )}
                            </ul>
                          </Row>
                        </div>
                      </Col>
                      <Col sm={12} lg={6} className="p-2">
                        <div
                          className={`attachments-wrapper pt-2 px-3 pb-0 ${
                            inputs.otherAttachment ? "active" : ""
                          }`}
                        >
                          <Row className="set-row-middle">
                            <h5 className="col-sm-6 title set-row-middle m-0">
                              <FileEarmark
                                size="1.6rem"
                                color="#F43321"
                                className="ms-2"
                              />
                              سایر
                            </h5>
                            <div className="col-sm-6 switch-buttons set-row-middle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="otherAttachment"
                                  checked={inputs.otherAttachment}
                                  id="otherAttachment"
                                  onChange={checkBoxChangeHandler}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={12} className="uploader">
                              <button
                                className="btn w-100"
                                disabled={!inputs.otherAttachment}
                                onClick={() => {
                                  document
                                    .querySelector("#otherUpload")
                                    .click();
                                }}
                              >
                                بارگذاری
                                <input
                                  className="d-none"
                                  id="otherUpload"
                                  type="file"
                                  accept=".txt,.pdf"
                                  name="otherPath"
                                  onChange={(e) => {
                                    attachmentFileChangeHandler(e, "other");
                                  }}
                                />
                              </button>
                            </Col>
                          </Row>
                          <Row className="attachments-list-wrapper">
                            <ul className="unstyled-list attachments-list set-column-middle p-0 m-0">
                              {otherAttachmentList.length > 0 ? (
                                otherAttachmentList.map((item, index) => {
                                  return (
                                    <li key={index}>
                                      {item.substring(0, 16) + "..."}
                                      <Trash
                                        size="1.1rem"
                                        className="float-start text-danger cpointer mx-1"
                                        onClick={() => {
                                          deleteThisAttachment(item, "other");
                                        }}
                                      />

                                      <a
                                        href={BASEURL + "/download?id=" + item}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Download
                                          size="1.1rem"
                                          className="float-start text-info cpointer me-4 ms-1"
                                        />
                                      </a>
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="text-center">
                                  فایلی به این بخش ضمیمه نشده است
                                </li>
                              )}
                            </ul>
                          </Row>
                        </div>
                      </Col>
                      <Col sm={12} lg={12} className="p-2">
                        <div
                          className={`attachments-wrapper p-2 ${
                            inputs.excelHyperLink ? "active" : ""
                          }`}
                        >
                          <Row className="set-row-middle">
                            <h5 className="col-sm-6 title set-row-middle m-0 text-nowrap">
                              <Link45deg
                                size="1.6rem"
                                color="#f0ad4e"
                                className="ms-2"
                              />
                              هایپرلینک روی اکسل
                            </h5>
                            <div className="col-sm-6 switch-buttons set-row-middle">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="excelHyperLink"
                                  checked={inputs.excelHyperLink}
                                  id="excelHyperLink"
                                  onChange={checkBoxChangeHandler}
                                />
                                <span className="slider"></span>
                              </label>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col sm={12} lg={7} className="leftImage-wrapper">
                {leftDocImage && (
                  <Row className="options">
                    <div className="set-row-middle">
                      <span
                        className="cpointer"
                        onClick={() => {
                          PrintImage(leftDocImage);
                          return false;
                        }}
                      >
                        <Printer size={"1.3rem"} color={"#4c68c1"} />
                      </span>
                    </div>
                    <div className="set-row-middle">
                      <a
                        href={
                          BASEURL +
                          "/download?id=" +
                          leftDocImage.replace(IMAGEURLS, "")
                        }
                        className="cpointer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Download size={"1.3rem"} color={"#4c68c1"} />
                      </a>
                    </div>
                    <div className="set-row-middle">
                      <span
                        className="cpointer"
                        onClick={() => {
                          document.querySelector("#newTabDocsImage").click();
                        }}
                      >
                        <ArrowsExpand size={"1.3rem"} color={"#4c68c1"} />
                      </span>
                    </div>
                  </Row>
                )}
                <div
                  className={`leftImage-container ${
                    docList.length === 0 ? "set-column-middle" : ""
                  }`}
                  onScroll={(e) => {
                    let el = e.target;
                    if (el.scrollTop > 150) {
                      if (!e.target.classList.contains("scrolling")) {
                        e.target.classList.add("scrolling");
                      }
                    } else {
                      e.target.classList.remove("scrolling");
                    }
                  }}
                >
                  {leftDocImage ? (
                    <ReactPanZoom alt="document image" image={leftDocImage} />
                  ) : (
                    <h4 className="text-center">
                      تصویری برای مشاهده انتخاب نشده است.
                    </h4>
                  )}
                  <a
                    id="newTabDocsImage"
                    className="d-none"
                    href={leftDocImage}
                    target="_blank"
                    rel="noreferrer"
                  >
                    newTabDocsImage
                  </a>
                </div>
                {docList.length > 0 && (
                  <div className="controls set-row-middle p-2">
                    <div className="w-50 d-flex justify-content-around">
                      <button
                        className="btn gradiantBg"
                        onClick={() => {
                          if (docListPointer !== docList.length - 1) {
                            setLeftDocImage(
                              IMAGEURLS + docList[docList.length - 1]
                            );
                            setDocListPointer(docList.length - 1);
                          }
                        }}
                      >
                        آخرین
                      </button>
                      <button
                        className="btn gradiantBg"
                        onClick={() => {
                          if (docListPointer !== docList.length - 1) {
                            setLeftDocImage(
                              IMAGEURLS + docList[docListPointer + 1]
                            );
                            setDocListPointer(docListPointer + 1);
                          }
                        }}
                      >
                        بعدی
                      </button>
                    </div>
                    <div className="w-50 d-flex justify-content-around">
                      <button
                        className="btn gradiantBg"
                        onClick={() => {
                          if (docListPointer !== 0) {
                            setLeftDocImage(
                              IMAGEURLS + docList[docListPointer - 1]
                            );
                            setDocListPointer(docListPointer - 1);
                          }
                        }}
                      >
                        قبلی
                      </button>
                      <button
                        className="btn gradiantBg"
                        onClick={() => {
                          if (docListPointer !== 0) {
                            setLeftDocImage(IMAGEURLS + docList[0]);
                            setDocListPointer(0);
                          }
                        }}
                      >
                        اولین
                      </button>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Portal show={docListModal.show}>
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  لیست اسناد یافت شده
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                  }}
                >
                  <Table striped bordered hover responsive>
                    <thead className="gradiantBg text-white text-center">
                      <tr>
                        <th scope="col" className="text-nowrap">
                          ردیف
                        </th>
                        <th scope="col" className="text-nowrap">
                          عملیات
                        </th>
                        {/* <th scope="col" className="text-nowrap">
                          نام سند
                        </th> */}
                        <th scope="col" className="text-nowrap">
                          نام پیمانکار
                        </th>
                        <th scope="col" className="text-nowrap">
                          تاریخ بارگذاری
                        </th>
                        <th scope="col" className="text-nowrap">
                          شماره فولدر
                        </th>
                        <th scope="col" className="text-nowrap">
                          تاریخ سند
                        </th>
                        <th scope="col" className="text-nowrap">
                          سال مالی
                        </th>
                        <th scope="col" className="text-nowrap">
                          مبلغ سند - ریال
                        </th>
                        <th scope="col" className="text-nowrap">
                          اصلی
                        </th>
                        <th scope="col" className="text-nowrap">
                          فرعی
                        </th>
                        <th scope="col" className="text-nowrap">
                          عطف
                        </th>
                        <th scope="col" className="text-nowrap">
                          زونکن
                        </th>
                        <th scope="col" className="text-nowrap">
                          جلد
                        </th>
                        <th scope="col" className="text-nowrap">
                          برگ
                        </th>
                        <th scope="col" className="text-nowrap">
                          نوع سند
                        </th>
                        <th scope="col" className="text-nowrap">
                          نام قسمت
                        </th>
                        <th scope="col" className="text-nowrap">
                          موضوع سند
                        </th>
                        <th scope="col" className="text-nowrap">
                          کلاسه پرونده
                        </th>
                        <th scope="col" className="text-nowrap">
                          نام پروژه
                        </th>
                        <th scope="col" className="text-nowrap">
                          تاریخ اسکن
                        </th>
                        <th scope="col" className="text-nowrap">
                          کاربر اسکن
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {docListModal.list.map((doc, index) => {
                        return (
                          <tr key={index} id={doc.nDocId}>
                            <td className="text-nowrap">{index + 1}</td>
                            <td className="set-row-middle">
                              <Button
                                className="text-nowrap"
                                onClick={() => {
                                  selectThisDocument(doc);
                                }}
                                variant={"primary"}
                              >
                                انتخاب سند
                              </Button>
                            </td>
                            {/* <td className="text-nowrap">{doc.docName}</td> */}
                            <td className="text-nowrap">{doc.peymankarName}</td>
                            <td className="text-nowrap">
                              {doc.insertDateTimeString}
                            </td>
                            <td className="text-nowrap">{doc.folderNumber}</td>
                            <td className="text-nowrap">
                              {doc.docDatePersian}
                            </td>
                            <td className="text-nowrap">{doc.maliYear}</td>
                            <td className="text-nowrap">
                              {doc.docPrice
                                ? new Intl.NumberFormat().format(
                                    parseInt(doc.docPrice)
                                  )
                                : 0}
                            </td>
                            <td className="text-nowrap">{doc.mainNumber}</td>
                            <td className="text-nowrap">{doc.subNumber}</td>
                            <td className="text-nowrap">{doc.atfNumber}</td>
                            <td className="text-nowrap">{doc.zonkanNumber}</td>
                            <td className="text-nowrap">{doc.jeldNumber}</td>
                            <td className="text-nowrap">{doc.bargNumber}</td>
                            <td className="text-nowrap">{doc.docTypeString}</td>
                            <td className="text-nowrap">
                              {doc.partNameString}
                            </td>
                            <td className="text-nowrap">{doc.docSubject}</td>
                            <td className="text-nowrap">{doc.docClass}</td>
                            <td className="text-nowrap">{doc.projectNameId}</td>
                            <td className="text-nowrap">
                              {doc.scanDatetimePersian}
                            </td>
                            <td className="text-nowrap">
                              {doc.scanPersonName}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    setDocListModal({ ...docListModal, show: false });
                  }}
                  variant={"danger"}
                >
                  بستن
                </Button>
              </Modal.Footer>
            </Portal>
            <hr />
            <Row>
              <Col sm={12} className="buttons">
                {activeWizard > 1 && (
                  <button
                    className="btn mx-2"
                    onClick={() => {
                      setActiveWizard(activeWizard - 1);
                    }}
                  >
                    قبلی
                  </button>
                )}
                {activeWizard < 3 && (
                  <button
                    className="btn mx-2"
                    onClick={() => {
                      setActiveWizard(activeWizard + 1);
                    }}
                  >
                    بعدی
                  </button>
                )}
                <button
                  id="saveDocumentBtn"
                  className="btn me-2"
                  onClick={() => {
                    if (mode === "SAVE") {
                      saveDoc();
                    } else {
                      updateDoc();
                    }
                  }}
                >
                  {mode === "SAVE" ? " ذخیره سند جدید" : "به روز رسانی سند"}
                </button>
              </Col>
            </Row>
          </div>
        </Wizard>
      </div>
    </Dashboard>
  );
}

export default NewDoc;
